import { ACTIONS, ACTION_GROUPS, createActions } from '../actions';
import { SECTIONS, SECTION_GROUPS, createSectionGroups } from '../sections';

const ROLE_CONFIG = {
  ADMIN: {
    name: 'Admin',
    forbiddenExceptions: {},
    permissions: {},
  },

  HR_ADMIN: {
    name: 'HR Admin',
    forbiddenExceptions: {},
    permissions: {
      // HR
      ...createSectionGroups(SECTION_GROUPS.HR, ACTION_GROUPS.ALL),
      ...createSectionGroups(SECTION_GROUPS.RATINGS, ACTION_GROUPS.ALL),
      ...createSectionGroups(SECTION_GROUPS.CONTROL_PANEL, ACTION_GROUPS.ALL),
      // Worker details
      ...createSectionGroups(SECTION_GROUPS.WORKER_DETAILS, ACTION_GROUPS.ALL),
      [SECTIONS.RATINGS_SCORING]: createActions(ACTIONS.VIEW),
    },
  },

  HR_SPECIALIST: {
    name: 'HR Specialist',
    forbiddenExceptions: {
      // HR
      ...createSectionGroups(SECTION_GROUPS.CONTROL_PANEL, ACTION_GROUPS.ALL),
    },
    permissions: {
      // HR
      ...createSectionGroups(SECTION_GROUPS.HR, ACTION_GROUPS.ALL),
      // Worker details
      ...createSectionGroups(SECTION_GROUPS.WORKER_DETAILS, ACTION_GROUPS.ALL),
    },
  },

  HR: {
    name: 'HR',
    forbiddenExceptions: {
      // HR
      ...createSectionGroups(SECTION_GROUPS.CONTROL_PANEL, ACTION_GROUPS.ALL),
      // Worker details
      [SECTIONS.WORKER_DETAILS_TAB__ALL_CONTRACTS_BTN]: createActions(ACTIONS.VIEW),
      [SECTIONS.WORKER_DETAILS_TAB__DOWNLOAD_CONTRACT_BTN]: createActions(ACTIONS.VIEW),
      [SECTIONS.WORKER_DETAILS_TAB__GENERAL_INFO_TAB__SALARY_TABLE]: ACTION_GROUPS.ALL,
      // Worker details 'Other documentation' modal
      [SECTIONS.WORKER_DETAILS_TAB__OTHER_DOCUMENTATION_MODAL__ALL_DOCUMENTS_TAB__IS_MID]: createActions(ACTIONS.VIEW),
      [SECTIONS.WORKER_DETAILS_TAB__OTHER_DOCUMENTATION_MODAL__DOCUMENT_TYPES_TAB__IS_MID]: createActions(ACTIONS.VIEW),
    },
    permissions: {
      // HR
      ...createSectionGroups(SECTION_GROUPS.HR, ACTION_GROUPS.ALL),
      // Worker details
      ...createSectionGroups(SECTION_GROUPS.WORKER_DETAILS, ACTION_GROUPS.ALL),
    },
  },

  MANAGER: {
    name: 'Manager',
    forbiddenExceptions: {
      [SECTIONS.DEPARTMENT_MANAGER]: createActions(ACTIONS.VIEW),
    },
    permissions: {
      // Worker details
      [SECTIONS.WORKER_DETAILS_TAB__BREADCRUMBS]: createActions(ACTIONS.VIEW),
    },
  },

  WORKER: {
    name: 'Worker',
    forbiddenExceptions: {
      // Worker details
      [SECTIONS.WORKER_DETAILS_TAB__BREADCRUMBS]: createActions(ACTIONS.VIEW),
      [SECTIONS.WORKER_DETAILS_TAB__ALL_CONTRACTS_BTN]: createActions(ACTIONS.VIEW),
      [SECTIONS.WORKER_DETAILS_TAB__DOWNLOAD_CONTRACT_BTN]: createActions(ACTIONS.VIEW),
      [SECTIONS.WORKER_DETAILS_TAB__GENERAL_INFO_TAB__SALARY_TABLE]: ACTION_GROUPS.ALL,
      // Worker details 'Other documentation' modal
      [SECTIONS.WORKER_DETAILS_TAB__OTHER_DOCUMENTATION_MODAL__DOCUMENT_TYPES_TAB]: createActions(ACTIONS.VIEW),
      [SECTIONS.WORKER_DETAILS_TAB__OTHER_DOCUMENTATION_MODAL__ALL_DOCUMENTS_TAB__IS_MID]: createActions(ACTIONS.VIEW),
      [SECTIONS.WORKER_DETAILS_TAB__OTHER_DOCUMENTATION_MODAL__DOCUMENT_TYPES_TAB__IS_MID]: createActions(ACTIONS.VIEW),
      // Worker details tabs
    },
    permissions: {
      // Worker details
      ...createSectionGroups(SECTION_GROUPS.WORKER_DETAILS, ACTION_GROUPS.READ_ONLY),
    },
  },
};

export default ROLE_CONFIG;
