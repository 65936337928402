import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Table, TableButtons, Button, ConfirmationModal } from 'shared';
import '../styles.scss';

import { getDataDefinitions, getPaginatedDataDefinitions, editDataDefinition } from '../../../actions';

import ScoringCategoriesModal from './ScoringCategoriesModal';

const ScoringCategories = ({ t, currentUser, companyId }) => {
  const [dataDefinitions, setDataDefinitions] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: false,
  });
  const [dataDefinitionsFilters, setDataDefinitionsFilters] = useState({
    selectedAscDesc: 'asc',
    selectedSort: 'order',
  });
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [modalData, setModalData] = useState({
    isOpen: false,
    selectedItem: null,
  });

  const fetchDataDefinitions = () => {
    setDataDefinitions((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const asc = dataDefinitionsFilters?.selectedAscDesc === 'desc' ? '-' : '';
    const apiFilters = `&limit=15&is_active=true&order_by=${asc}${dataDefinitionsFilters?.selectedSort}`;

    getDataDefinitions(companyId, apiFilters)
      .then((res) => {
        setDataDefinitions({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setDataDefinitions((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchPaginatedDataDefinitions = (url) => {
    setDataDefinitions((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getPaginatedDataDefinitions(url)
      .then((res) => {
        setDataDefinitions({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setDataDefinitions((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  useEffect(() => {
    fetchDataDefinitions();
  }, []);

  useEffect(() => {
    fetchDataDefinitions();
  }, [dataDefinitionsFilters]);

  const handleSorting = (sortData) => {
    const sortKey = sortData.id;

    setDataDefinitionsFilters((prevState) => ({
      ...prevState,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
      selectedSort: sortKey,
    }));
  };

  const handleAddNew = () => {
    setModalData({
      isOpen: true,
      selectedItem: null,
    });
  };

  const handleEdit = (row) => {
    setModalData({
      isOpen: true,
      selectedItem: row,
    });
  };

  const handleShowConfirmationDialog = (original) => {
    setShowConfirmationDialog(true);
    setDeleteData(original);
  };

  const handleDeleteItem = async () => {
    // await deleteDataDefinition(deleteData?.original?.id, companyId);
    const payload = {
      ...deleteData?.original,
      is_active: false,
    };
    await editDataDefinition(companyId, deleteData?.id, payload)
      .then(() => {
        setShowConfirmationDialog(false);
        fetchDataDefinitions();
      })
      .catch(() => {
        setShowConfirmationDialog(false);
      });
  };

  const handleCloseModal = () => {
    setModalData({
      isOpen: false,
      selectedItem: null,
    });
  };

  const closeModalAndRefetch = () => {
    handleCloseModal();
    fetchDataDefinitions();
  };

  return (
    <React.Fragment>
    <div className="data_definitions_table">

      <div className="table_action_buttons">
        <Button type="add" onClick={handleAddNew}>
          {t('page_content.human_resources.control_panel.category_scoring.button_new_scoring_category')}
        </Button>
      </div>

      <Table
        style={{ userSelect: 'text' }}
        columns={[
          {
            Header: () => <span>{t('page_content.human_resources.control_panel.category_scoring.field_placeholder_order')}</span>,
            accessor: 'order',
            width: 100,
            style: {
              cursor: 'default',
            },
          },
          {
            Header: () => <span>{t('page_content.human_resources.control_panel.category_scoring.field_placeholder_definition')}</span>,
            accessor: 'name',
            style: {
              cursor: 'default',
            },
          },
        ]}
        data={dataDefinitions.data || []}
        defaultPageSize={15}
        loading={dataDefinitions.isLoading}
        minRows={0}
        enableEdit
        enableDelete
        onEdit={(original) => handleEdit(original)}
        onDelete={(original) => handleShowConfirmationDialog(original)}
        defaultSorted={[{ id: 'order', desc: false }]}
        onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
      />
      <div>
        <span style={{ float: 'right' }}>
          <TableButtons
            previous={dataDefinitions.previous}
            next={dataDefinitions.next}
            fetchFunction={fetchPaginatedDataDefinitions}
            count={dataDefinitions.count}
          />
        </span>
      </div>
    </div>

    {modalData?.isOpen &&
    <ScoringCategoriesModal
      companyId={companyId}
      currentUser={currentUser}
      isOpen={modalData?.isOpen}
      handleCloseModal={handleCloseModal}
      initialValues={modalData?.selectedItem}
      closeModalAndRefetch={closeModalAndRefetch}
    />}

    <ConfirmationModal
      itemName={`${get(deleteData, 'name')}`}
      showModal={showConfirmationDialog}
      handleCloseModal={() => setShowConfirmationDialog(false)}
      handleConfirmModal={handleDeleteItem}
      type="warning"
    />
  </React.Fragment>
  );
};

ScoringCategories.propTypes = {
  t: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  companyId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: get(state, 'currentUser', null),
    companyId: get(state, 'app.company.id', null),
  };
};

export default connect(mapStateToProps, null)(withTranslation()(ScoringCategories));
