import { getByURL, postAndNotifyByURL, putAndNotifyByURL } from './CustomService';

// -------------------- ASSET UTILIZATIONS -------------------- //
export const getIndustryAssetUtilizations = async (filters = '') => {
  return getByURL(`/api/v1/industry/asset_utilizations/${filters}`);
};

export const postIndustryAssetUtilization = async (filters = '', data) => {
  return postAndNotifyByURL(`/api/v1/industry/asset_utilizations/${filters}`, data);
};

export const putIndustryAssetUtilization = async (id, filters = '', data) => {
  return putAndNotifyByURL(`/api/v1/industry/asset_utilizations/${id}/${filters}`, data);
};

// -------------------- TIMINGS -------------------- //
export const getIndustryTimings = async (filters = '') => {
  return getByURL(`/api/v1/industry/timings/${filters}`);
};

export const postIndustryTimings = async (filters = '', data) => {
  return postAndNotifyByURL(`/api/v1/industry/timings/${filters}`, data);
};

export const putIndustryTimings = async (id, filters = '', data) => {
  return putAndNotifyByURL(`/api/v1/industry/timings/${id}/${filters}`, data);
};

// -------------------- ASSETS -------------------- //
export const getIndustryAssets = async (filters = '') => {
  return getByURL(`/api/v1/industry/assets/${filters}`);
};
