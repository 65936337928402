import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';

import { Table, TableButtons, Button } from 'shared';
import { getLocale } from 'shared/DatePicker/constants';
import { defaultDateTimeFormat } from 'shared/constants';
import { selectModalStyles } from 'styles/modules/reactSelect';

import { getNotificationEvents, getNotificationLogs, getByURL } from 'shared/Api';
import './styles.scss';

const AuditLogs = ({ t, companyId }) => {
  const [auditLogs, setAuditLogs] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: true,
  });

  const [events, setEvents] = useState({
    data: [],
    isLoading: true,
  });

  const [filters, setFilters] = useState({
    selectedAscDesc: 'desc',
    selectedSort: 'created_at',
    startDate: null,
    endDate: null,
    selectedEvent: null,
  });

  const fetchEvents = () => {
    setEvents((prevState) => ({ ...prevState, isLoading: true }));

    const apiFilters = `?company=${companyId}&limit=9999`;

    getNotificationEvents(apiFilters)
      .then((res) => {
        setEvents({
          data: get(res, 'data.results', []),
          isLoading: false,
        });
      })
      .catch(() => setEvents((prevState) => ({ ...prevState, isLoading: false })));
  };

  const fetchAuditLogs = () => {
    setAuditLogs((prevState) => ({ ...prevState, isLoading: true }));

    const asc = filters?.selectedAscDesc === 'desc' ? '-' : '';
    let apiFilters = `?company=${companyId}&limit=30&order_by=${asc}${filters?.selectedSort}`;

    if (filters?.selectedEvent?.code) apiFilters += `&event_code=${filters?.selectedEvent.code}`;
    if (filters?.startDate) apiFilters += `&created_at_after=${moment(filters?.startDate).utc().toISOString()}`;
    if (filters?.endDate) apiFilters += `&created_at_before=${moment(filters?.endDate).utc().toISOString()}`;

    getNotificationLogs(apiFilters)
      .then((res) => {
        setAuditLogs({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setAuditLogs((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchPaginatedAuditLogs = (url) => {
    setAuditLogs((prevState) => ({ ...prevState, isLoading: true }));

    getByURL(url)
      .then((res) => {
        setAuditLogs({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setAuditLogs((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  useEffect(() => {
    fetchAuditLogs();
  }, [companyId, filters]);

  useEffect(() => {
    fetchEvents();
  }, [companyId]);

  const handleSorting = (sortData) => {
    let sortKey = sortData.id;
    sortKey = sortKey.replace('.', '__');

    setFilters((prevState) => ({
      ...prevState,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
      selectedSort: sortKey,
    }));
  };

  const setSelectedFilters = (key, value) => {
    setFilters((prevState) => ({ ...prevState, [key]: value }));
  };

  const clearFilters = () => {
    setFilters({
      selectedAscDesc: 'desc',
      selectedSort: 'created_at',
      startDate: null,
      endDate: null,
      selectedEvent: null,
    });
  };

  return (
        <div className="events-audit-logs">
            <div className="events-audit-logs__filters">
                <div style={{ width: '200px' }}>
                    <Select
                      options={events.data}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      isLoading={events.isLoading}
                      isClearable
                      isSearchable
                      menuPosition="fixed"
                      placeholder={t('settings.events_and_subscribers.audit_logs.filter_by_event')}
                      onChange={(value) => setSelectedFilters('selectedEvent', value)}
                      value={filters?.selectedEvent || ''}
                      styles={selectModalStyles}
                    />
                </div>
                <div>
                    <ReactDatePicker
                      className="datepicker_input"
                      placeholderText={t('settings.events_and_subscribers.audit_logs.select_start_date')}
                      dateFormat="dd.MM.yyyy HH:mm"
                      maxDate={moment(filters?.endDate).toDate()}
                      selected={filters?.startDate ? moment(filters?.startDate).toDate() : null}
                      selectsStart
                      showTimeSelect
                      timeFormat="HH:mm"
                      disabledKeyboardNavigation
                      timeIntervals={1}
                      onChange={(value) => setSelectedFilters('startDate', value)}
                      locale={getLocale(t)}
                    />
                </div>
                <div>
                    <ReactDatePicker
                      className="datepicker_input"
                      placeholderText={t('settings.events_and_subscribers.audit_logs.select_end_date')}
                      dateFormat="dd.MM.yyyy HH:mm"
                      minDate={moment(filters?.startDate).toDate()}
                      selected={filters?.endDate ? moment(filters?.endDate).toDate() : null}
                      selectsStart
                      showTimeSelect
                      timeFormat="HH:mm"
                      disabledKeyboardNavigation
                      timeIntervals={1}
                      onChange={(value) => setSelectedFilters('endDate', value)}
                      locale={getLocale(t)}
                    />
                </div>
                <Button onClick={clearFilters}>{t('shared.clear_button')}</Button>
            </div>
            <div className="events-audit-logs__table">
                <Table
                  style={{ userSelect: 'text' }}
                  columns={[
                    {
                      Header: () => <span>{t('settings.events_and_subscribers.audit_logs.created_at')}</span>,
                      accessor: 'created_at',
                      Cell: (row) => (row.value ? moment(row.value).format(defaultDateTimeFormat) : '-'),
                    },
                    {
                      Header: () => <span>{t('settings.events_and_subscribers.audit_logs.event')}</span>,
                      accessor: 'event.name',
                      Cell: (row) => <span>{row?.value || '-'}</span>,
                    },
                    {
                      Header: () => <span>{t('settings.events_and_subscribers.audit_logs.notification_type')}</span>,
                      accessor: 'notification_type',
                      Cell: (row) => <span>{row?.value || '-'}</span>,
                    },
                  ]}
                  minRows={0}
                  noDataText=""
                  defaultPageSize={100}
                  data={auditLogs?.data || []}
                  loading={auditLogs?.isLoading}
                  defaultSorted={[{ id: 'created_at', desc: true }]}
                  onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
                  enableEdit={false}
                  enableDelete={false}
                  isActionsDisabled
                />
                <span style={{ float: 'right' }}>
                    <TableButtons
                      next={auditLogs?.next}
                      count={auditLogs?.count}
                      previous={auditLogs?.previous}
                      fetchFunction={fetchPaginatedAuditLogs}
                    />
                </span>
            </div>
        </div>
  );
};

AuditLogs.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default AuditLogs;
