import React, { Component } from 'react';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { numberSeparatorFormat } from 'industry/helpers';
import { Table, Button, TableButtons, ConfirmationModal } from 'shared';
import { selectStyles } from 'styles/modules/reactSelect';
import { getPaginatedOeeGoals, getOeeGoals, getOeeAssets, deleteOeeGoal } from '../actions';
import '../styles.scss';

import OEEGoalsModal from './OEEGoalsModal';

class OEEGoals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingOeeGoals: false,
      next: null,
      previous: null,
      count: null,
      oeeGoals: [],
      isOeeGoalsModalOpen: false,
      selectedOee: null,
      oeeAssets: [],
      assetFilterValue: '',
      selectedAscDesc: 'asc',
      selectedSort: 'asset',
    };
  }

  componentDidMount() {
    this.fetchOeeGoals();
    this.fetchOeeAssets();
  }

  handleSorting = (sortData) => {
    const column = sortData.id;
    let sortKey = column;
    if (column === 'asset.name') {
      sortKey = 'asset__name';
    }

    this.setState({
      selectedSort: sortKey,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.fetchOeeGoals();
    });
  }

  fetchOeeAssets = () => {
    const { locationId, companyId } = this.props;

    this.setState({
      isLoadingGoalsModal: true,
    });
    getOeeAssets(locationId, companyId)
      .then((re) => {
        this.setState({
          oeeAssets: get(re, 'data.results') || [],
          isLoadingGoalsModal: false,
        });
      });
  }

  fetchOeeGoals = () => {
    this.setState({
      isLoadingOeeGoals: true,
    });
    const { locationId } = this.props;
    const { assetFilterValue, selectedAscDesc, selectedSort } = this.state;

    let filters = '';
    if (assetFilterValue) {
      filters += `&asset=${assetFilterValue}`;
    }

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    filters += `&order_by=${asc}${selectedSort}`;

    getOeeGoals(locationId, filters)
      .then((re) => {
        this.setState({
          oeeGoals: get(re, 'data.results') || [],
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          count: get(re, 'data.count'),
          isLoadingOeeGoals: false,
        });
      });
  }

  fetchPaginatedOeeGoals = (url) => {
    this.setState({
      isLoadingOeeGoals: true,
    });
    getPaginatedOeeGoals(url)
      .then((re) => {
        this.setState({
          oeeGoals: get(re, 'data.results') || [],
          next: get(re, 'data.next'),
          previous: get(re, 'data.previous'),
          isLoadingOeeGoals: false,
        });
      });
  }

  openOeeGoalsModal = (rowInfo) => {
    this.setState({
      isOeeGoalsModalOpen: true,
      selectedOee: rowInfo || null,
    });
  }

  handleShowConfirmationModal = (rowInfo) => {
    this.setState({ deleteData: rowInfo?.id, showConfirmationDialog: true });
  }

  handleDeleteOeeGoal = () => {
    const { locationId } = this.props;
    const id = this.state.deleteData;
    this.setState({
      isLoadingGoalsModal: true,
    });
    deleteOeeGoal(id, locationId)
      .then(() => {
        this.setState({
          isLoadingGoalsModal: false,
        });
        this.handleCloseModal();
        this.fetchOeeGoals();
      });
    this.setState({ showConfirmationDialog: false });
  }

  handleQueryOrFilterChange = (key, value) => {
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }), async () => {
      await this.fetchOeeGoals();
    });
  }

  handleCloseModal = () => {
    this.setState({
      isOeeGoalsModalOpen: false,
      selectedOee: null,
      isLoadingGoalsModal: false,
    });
  }

  handleCloseAndRefetchModal = () => {
    this.setState({
      isOeeGoalsModalOpen: false,
      selectedOee: null,
      isLoadingGoalsModal: false,
    }, () => {
      this.fetchOeeGoals();
    });
  }

  render() {
    const { t, i18n, isReadOnly, companyId, locationId } = this.props;
    const {
      oeeGoals,
      previous,
      next,
      isLoadingOeeGoals,
      isOeeGoalsModalOpen,
      selectedOee,
      oeeAssets,
      isLoadingGoalsModal,
      assetFilterValue,
      count,
    } = this.state;

    const tableColumnConfig = [
      {
        Header: () => <span>{t('page_content.oee.table_column_asset')}</span>,
        accessor: 'asset.name',
        Cell: (row) => (row?.original?.is_factory ? t('page_content.oee.factory') : row.value ? row.value : t('page_content.oee.modal.asset_option')),
      },
      {
        Header: () => <span>{t('page_content.oee.table_column_goal')}</span>,
        accessor: 'goal',
        Cell: (row) => (row && row.value ? numberSeparatorFormat(i18n.language, row.value, 2, 2, true) : ' '),
      },
      {
        Header: () => <span>{t('page_content.oee.table_column_low')}</span>,
        accessor: 'low',
        Cell: (row) => (row && row.value ? numberSeparatorFormat(i18n.language, row.value, 2, 2, true) : ' '),
      },
      {
        Header: () => <span>{t('page_content.oee.table_column_high')}</span>,
        accessor: 'high',
        Cell: (row) => (row && row.value ? numberSeparatorFormat(i18n.language, row.value, 2, 2, true) : ' '),
      },
    ];

    return (
      <div className="industry-tab fullscreen oee_container">
        <div className="filter_row">
          <Select
            options={oeeAssets}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            isSearchable
            placeholder={t('page_content.oee.filter.assets')}
            onChange={(value) => this.handleQueryOrFilterChange('assetFilterValue', value.id)}
            value={(oeeAssets.find((asset) => asset.id === assetFilterValue)) || ''}
            styles={selectStyles}
          />
          <Button
            onClick={() => {
              this.setState({
                assetFilterValue: '',
              }, () => {
                this.handleQueryOrFilterChange();
              });
            }}
          >{t('page_content.dms.button_clear_all')}</Button>
          <div className="create_button_wrapper">
            <Button
              disabled={isReadOnly}
              onClick={() => {
                this.openOeeGoalsModal();
              }}
              type="add"
            >{t('page_content.oee.filter.create_goal_button')}</Button>
          </div>
        </div>
        <div className="table_area">
          <Table
            style={{ userSelect: 'text' }}
            columns={tableColumnConfig}
            data={oeeGoals}
            minRows={0}
            defaultPageSize={30}
            noDataText=" "
            showPagination={false}
            loading={isLoadingOeeGoals}
            enableEdit
            enableDelete
            onEdit={(rowInfo) => !isReadOnly && this.openOeeGoalsModal(rowInfo)}
            onDelete={(rowInfo) => this.handleShowConfirmationModal(rowInfo)}
            defaultSorted={[{ id: 'asset.name', desc: false }]}
            onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
          />
          <TableButtons previous={previous} next={next} fetchFunction={this.fetchPaginatedOeeGoals} count={count} />
        </div>

        {
          isOeeGoalsModalOpen &&
          <OEEGoalsModal
            selectedOee={selectedOee}
            oeeAssets={oeeAssets}
            t={t}
            handleCloseModal={this.handleCloseModal}
            isModalOpen={isOeeGoalsModalOpen}
            isLoadingModal={isLoadingGoalsModal}
            companyId={companyId}
            locationId={locationId}
            handleCloseAndRefetchModal={this.handleCloseAndRefetchModal}
          />
        }

        <ConfirmationModal
          showModal={this.state.showConfirmationDialog}
          handleCloseModal={() => this.setState({ showConfirmationDialog: false })}
          handleConfirmModal={this.handleDeleteOeeGoal}
          type="warning"
        />
      </div>
    );
  }
}

OEEGoals.propTypes = {
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object,
  isReadOnly: PropTypes.bool.isRequired,
};

export default (withRouter(OEEGoals));
