import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { selectModalStyles } from 'styles/modules/reactSelect';
import { Button, ContentLoader, Notification } from 'shared';
import { IconWarning, IconRemove } from 'shared/Icons';
import { defaultDateFormat } from 'shared/constants';
import { colors } from 'shared/colors';
import '../../../styles.scss';

import { ACTIONS } from 'industry/role/definitions/actions';
import { SECTIONS } from 'industry/role/definitions/sections';
import { canPerformAction } from 'industry/role/selectors';

import {
  addEvaluation,
  getEvaluations,
  getValueOptions,
  getCompanyValues,
  addWorkerEvaluation,
  getWorkerEvaluations,
  deleteWorkerEvaluations,
} from '../../../actions';

const CompanyValuesRating = ({ t, currentUser, companyId, selectedWorker, can }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [note, setNote] = useState('');
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const [companyValues, setCompanyValues] = useState({
    data: [],
    isLoading: true,
  });

  const [valueOptions, setValueOptions] = useState({
    data: [],
    isLoading: true,
  });

  const [workerEvaluations, setWorkerEvaluations] = useState({
    isLoading: true,
    data: [],
    selectedItem: null,
  });

  const fetchCompanyValues = () => {
    setCompanyValues((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getCompanyValues(companyId)
      .then((res) => {
        setCompanyValues({
          data: get(res, 'data.results', []),
          isLoading: false,
        });
      })
      .catch(() => {
        setCompanyValues((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchValueOptions = () => {
    setValueOptions((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getValueOptions(companyId)
      .then((res) => {
        setValueOptions({
          data: get(res, 'data.results', []),
          isLoading: false,
        });
      })
      .catch(() => {
        setValueOptions((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchWorkerEvaluations = () => {
    setWorkerEvaluations((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getWorkerEvaluations(companyId, `&worker=${selectedWorker?.id}&order_by=-created_at`)
      .then((res) => {
        setWorkerEvaluations({
          data: get(res, 'data.results', []),
          isLoading: false,
        });
      })
      .catch(() => {
        setWorkerEvaluations((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  useEffect(() => {
    fetchCompanyValues();
    fetchValueOptions();
    fetchWorkerEvaluations();
  }, []);

  const onFormChange = (name, option, value) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: {
        ...prevFormValues[name],
        [option]: value,
      },
    }));
  };

  const onWorkerEvaluationChange = (value) => {
    setWorkerEvaluations((prevState) => ({
      ...prevState,
      selectedItem: value,
    }));
    setFormValues({});
    setNote(value?.note || '');

    if (value) {
      setIsLoading(true);
      getEvaluations(companyId, `&worker_evaluation=${value?.id}`)
        .then((res) => {
          const selectedEvaluationData = get(res, 'data.results', []);

          const evaluationAnswers = selectedEvaluationData.reduce((acc, answer) => {
            const { option } = answer;
            const { value: valueOption } = option;

            if (!acc[valueOption?.id]) {
              acc[valueOption?.id] = {
                option1: null,
                option2: null,
              };
            }

            if (!acc[valueOption?.id].option1) {
              acc[valueOption?.id].option1 = option;
            } else {
              acc[valueOption?.id].option2 = option;
            }

            return acc;
          }, {});

          setFormValues(evaluationAnswers);
          setIsLoading(false);
        })
        .catch(() => {
          setWorkerEvaluations((prevState) => ({
            ...prevState,
            selectedItem: null,
          }));
          setIsLoading(false);
        });
    }
  };

  const handleSave = () => {
    setIsLoading(true);

    const evaluationPayload = {
      date: moment().format('YYYY-MM-DD'),
      worker: selectedWorker?.id,
      evaluator: currentUser?.worker_id,
      note,
    };

    addWorkerEvaluation(companyId, evaluationPayload)
      .then((res) => {
        const evaluationId = get(res, 'data.id', null);

        // eslint-disable-next-line no-unused-vars
        const options = Object.entries(formValues).reduce((acc, [name, { option1, option2 }]) => {
          acc.push({
            evaluation: evaluationId,
            option: option1?.id,
          });
          acc.push({
            evaluation: evaluationId,
            option: option2?.id,
          });
          return acc;
        }, []);

        const optionPromises = options.map((option) => addEvaluation(companyId, option)
          .then((response) => response));
        return Promise.all(optionPromises);
      })
      .then(() => {
        setFormValues({});
        setNote('');
        setIsLoading(false);
        fetchWorkerEvaluations();
      })
      .catch((error) => {
        Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const allSelectsFilled = companyValues?.data?.every((value) => {
      const { option1, option2 } = formValues[value.id] || {};
      return option1 && option2;
    });
    setIsSaveDisabled(!allSelectsFilled);
  }, [companyValues, formValues]);

  const handleDeleteEvaluation = (evaluationId) => {
    deleteWorkerEvaluations(companyId, evaluationId)
      .then(() => {
        fetchWorkerEvaluations();
        setFormValues({});
        setNote('');
      });
  };

  if (isLoading || companyValues?.isLoading || valueOptions?.isLoading) {
    return <ContentLoader />;
  }

  if (!currentUser?.worker_id) {
    return (
      <span className="ratings__screen__warning">
        <IconWarning color={colors.red} height="18px" width="18px" />
        <p>{t('page_content.human_resources.rating.no_rating')}</p>
      </span>);
  }

  return (
    <div className="ratings__screen">
      <span style={{ width: '300px' }}>
        <Select
          options={workerEvaluations?.data}
          getOptionLabel={(option) => <span>{t('page_content.human_resources.rating.evaluation')} - {moment(option.date).format(defaultDateFormat)}</span>}
          getOptionValue={(option) => option}
          isClearable
          placeholder={t('page_content.human_resources.rating.view_previous_evaluations')}
          menuPosition="fixed"
          onChange={(e) => onWorkerEvaluationChange(e || null)}
          value={(workerEvaluations?.data?.find((sOption) => (sOption?.id === workerEvaluations?.selectedItem?.id))) || ''}
          styles={selectModalStyles}
        />
      </span>

      {
        workerEvaluations?.selectedItem?.evaluator &&
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ fontWeight: '600', margin: 0 }}>{`${t('page_content.human_resources.rating.evaluator')}: ${workerEvaluations?.selectedItem?.evaluator?.name} ${workerEvaluations?.selectedItem?.evaluator?.last_name}`}</p>
          {
            can(SECTIONS.RATINGS_TABS__COMPANY_VALUES_TAB, ACTIONS.DELETE) &&
            <Button
              type="delete"
              style={{ marginLeft: 'auto' }}
              onClick={() => handleDeleteEvaluation(workerEvaluations?.selectedItem?.id)}
            >
              <IconRemove width="14px" height="14px" />
            </Button>
          }
        </div>
      }

      <div className="custom_inputs_view">
        {
          companyValues?.data?.map((value) => (
            <div className="modal_row">
              <div className="left_text">
                <label>{value?.name}*</label>
              </div>

              <div className="multiple_right_select">
                <Select
                  options={valueOptions?.data?.filter((option) => option?.value?.id === value?.id)?.sort(() => Math.random() - 0.5)}
                  getOptionLabel={(option) => (can(SECTIONS.RATINGS_SCORING, ACTIONS.VIEW)
                    ? `${option.name} - ${option?.score?.score} ${t('page_content.human_resources.rating.points')}`
                    : `${option.name}`)}
                  getOptionValue={(option) => option.id}
                  isSearchable
                  placeholder="..."
                  onChange={(opt) => onFormChange(value?.id, 'option1', opt)}
                  value={formValues[value.id]?.option1 || null}
                  styles={selectModalStyles}
                  isDisabled={workerEvaluations?.selectedItem}
                />
                <Select
                  options={valueOptions?.data?.filter((option) => option?.value?.id === value?.id)?.sort(() => Math.random() - 0.5)}
                  getOptionLabel={(option) => (can(SECTIONS.RATINGS_SCORING, ACTIONS.VIEW)
                    ? `${option.name} - ${option?.score?.score} ${t('page_content.human_resources.rating.points')}`
                    : `${option.name}`)}
                  getOptionValue={(option) => option.id}
                  isSearchable
                  placeholder="..."
                  onChange={(opt) => onFormChange(value?.id, 'option2', opt)}
                  value={formValues[value.id]?.option2 || null}
                  styles={selectModalStyles}
                  isDisabled={workerEvaluations?.selectedItem}
                />
              </div>
            </div>))
        }

        <div className="modal_row">
          <div className="left_text">{t('page_content.human_resources.rating.comment')}*</div>
          <div className="right_textarea">
            <textarea type="text" value={note} onChange={(e) => setNote(e.target.value)} disabled={workerEvaluations?.selectedItem} />
          </div>
        </div>
      </div>
      {
        !workerEvaluations?.selectedItem &&
        <div className="save_button">
          <Button
            disabled={isSaveDisabled || !note}
            type="success"
            onClick={handleSave}
          >{t('page_content.human_resources.rating.save')}</Button>
        </div>
      }
    </div>
  );
};

CompanyValuesRating.propTypes = {
  t: PropTypes.func,
  can: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  currentUser: PropTypes.object.isRequired,
  selectedWorker: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: get(state, 'currentUser', null),
    companyId: get(state, 'app.company.id', null),
    can: (section, action, useExceptions = false) => canPerformAction(state, section, action, useExceptions),
  };
};

export default connect(mapStateToProps, null)(withTranslation()(CompanyValuesRating));
