import { getByURL, postAndNotifyByURL, putAndNotifyByURL, deleteAndNotifyByURL } from './CustomService';

// -------------------- WORKFORCE WORKER CATEGORIES -------------------- //
export const getWorkforceWorkerCategory = async (filters = '') => {
  return getByURL(`/api/v1/workforce/worker_categories/${filters}`);
};

// -------------------- WORKFORCE BUDGETS -------------------- //
export const getWorkforceBudgets = async (filters = '') => {
  return getByURL(`/api/v1/workforce/budgets/${filters}`);
};

export const postWorkforceBudget = async (filters = '', data) => {
  return postAndNotifyByURL(`/api/v1/workforce/budgets/${filters}`, data);
};

export const putWorkforceBudget = async (id, filters = '', data) => {
  return putAndNotifyByURL(`/api/v1/workforce/budgets/${id}/${filters}`, data);
};

export const deleteWorkforceBudget = async (id, filters = '') => {
  return deleteAndNotifyByURL(`/api/v1/workforce/budgets/${id}/${filters}`);
};

// -------------------- WORKFORCE WORKERS -------------------- //
export const getWorkforceWorkers = async (filters = '') => {
  return getByURL(`/api/v1/workforce/workers/${filters}`);
};

export const getWorkforceWorkerDetails = async (id, filters = '') => {
  return getByURL(`/api/v1/workforce/workers/${id}/${filters}`);
};

// -------------------- WORKFORCE WORKING PLACES -------------------- //
export const getWorkforceWorkingPlaces = async (filters = '') => {
  return getByURL(`/api/v1/workforce/working_places/${filters}`);
};

// -------------------- WORKFORCE WORKER WORKING PLACES -------------------- //
export const getWorkforceWorkerWorkingPlaces = async (filters = '') => {
  return getByURL(`/api/v1/workforce/worker_working_places/${filters}`);
};

// -------------------- WORKFORCE SALARIES -------------------- //
export const getWorkforceSalaries = async (filters = '') => {
  return getByURL(`/api/v1/workforce/salaries/${filters}`);
};

// -------------------- WORKFORCE INJURIES -------------------- //
export const getWorkforceInjuries = async (filters = '') => {
  return getByURL(`/api/v1/workforce/injuries/${filters}`);
};

// -------------------- WORKFORCE DOCUMENTS -------------------- //
export const getWorkforceDocuments = async (filters = '') => {
  return getByURL(`/api/v1/workforce/documents/${filters}`);
};

// -------------------- WORKFORCE DOCUMENT TYPES  -------------------- //
export const getWorkforceDocumentTypes = async (filters = '') => {
  return getByURL(`/api/v1/workforce/document_types/${filters}`);
};

// -------------------- WORKFORCE ABSENCES -------------------- //
export const getWorkforceAbsences = async (filters = '') => {
  return getByURL(`/api/v1/workforce/worker_absences/${filters}`);
};
