import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import WorkersList from './components/WorkersList';
import WorkersAvailability from './components/WorkersAvailability';
import QualificationsAndProfessions from './components/QualificationsAndProfessions';

const Workers = ({
  t,
  hrTabs,
  department,
}) => {
  const shouldDisplayTab = (tabName) => {
    const defaultHrTabs = hrTabs || [];
    return defaultHrTabs.some((o) => o.name === tabName);
  };
  return (
      <Tabs>
        <TabList>
          <Tab>{t('page_content.human_resources.workers.workers_list')}</Tab>
          {shouldDisplayTab('Workers -> Workers Availability') && <Tab>{t('page_content.human_resources.workers.workers_availability')}</Tab>}
          {shouldDisplayTab('Workers -> Qualifications and professions') && <Tab>{t('page_content.human_resources.workers.qualifications_and_professions')}</Tab>}
        </TabList>

        <TabPanel>
          <WorkersList
            department={department}
          />
        </TabPanel>

        <TabPanel>
          <WorkersAvailability />
        </TabPanel>

        <TabPanel>
          <QualificationsAndProfessions />
          </TabPanel>
      </Tabs>
  );
};

Workers.propTypes = {
  t: PropTypes.func,
  department: PropTypes.number,
  hrTabs: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    hrTabs: get(state, 'app.companyConfig.config.hr_tabs', null),
  };
}

export default connect(mapStateToProps)(withTranslation()(Workers));
