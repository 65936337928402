import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { ContentLoader } from 'shared';
import './styles.scss';

import { ACTIONS } from 'industry/role/definitions/actions';
import { SECTIONS } from 'industry/role/definitions/sections';
import { canPerformAction } from 'industry/role/selectors';
import { determineUserRole as determineUserRoleAction } from 'industry/role/roleActions';

import Workers from './components/Workers';
import Forms from './components/Forms/Forms';
import Shifts from './components/Shifts/Shifts';
import Ratings from './components/Ratings/Ratings';
import ControlPanel from './components/ControlPanel/index';
import WorkingInjuries from './components/WorkingInjuries';
import Employments from './components/Employments/Employments';
import DepartmentsTab from './components/Departments/DepartmentsTab';
import AssignedEquipment from './components/AssignedEquipment/index';

const IndustryLocationHumanResources = ({ t, history, showWorkingInjuries, hrTabs, determineUserRole, can, roleLoading }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [department, setDepartment] = useState(null);

  useEffect(() => {
    setSelectedTab(history?.location?.state?.tabIndex || 0);

    determineUserRole();
  }, [determineUserRole]);

  const filterByDepartment = (value) => {
    setSelectedTab(0);
    setDepartment(value);
  };

  const handleSelectTab = (e) => {
    setSelectedTab(e);
    setDepartment(null);
    history.replace(history.location.pathname, { tabIndex: e });
  };

  const shouldDisplayTab = (tabName, section) => {
    const defaultHrTabs = hrTabs || [{ name: 'Workers' }];
    return defaultHrTabs.some((o) => o.name === tabName) && can(section, ACTIONS.VIEW);
  };

  return (
    <div className="industry-tab human_resources_container fullscreen">
      {
        roleLoading
          ? <ContentLoader /> :
          <Tabs selectedIndex={selectedTab} onSelect={handleSelectTab}>
          <TabList>
            {shouldDisplayTab('Workers', SECTIONS.WORKERS_TABS) && <Tab>{t('page_content.human_resources.workers_tab')}</Tab>}
            {shouldDisplayTab('Employments', SECTIONS.EMPLOYMENTS_TAB) && <Tab>{t('page_content.human_resources.layoffs_tab')}</Tab>}
            {shouldDisplayTab('Departments', SECTIONS.DEPARTMENTS_TAB) && <Tab>{t('page_content.human_resources.departments_tab')}</Tab>}
            {shouldDisplayTab('Shifts', SECTIONS.SHIFTS_TABS) && <Tab>{t('page_content.human_resources.shifts_tab')}</Tab>}
            {shouldDisplayTab('Forms', SECTIONS.FORMS_TABS) && <Tab>{t('page_content.human_resources.forms_tab')}</Tab>}
            {shouldDisplayTab('Assigned equipment', SECTIONS.ASSIGNED_EQUIPMENT_TABS) && <Tab>{t('page_content.human_resources.assigned_equipment_tab')}</Tab>}
            {showWorkingInjuries && can(SECTIONS.WORKING_INJURIES_TAB, ACTIONS.VIEW) && <Tab>{t('page_content.human_resources.injuries.working_injuries')}</Tab>}
            {shouldDisplayTab('Ratings', SECTIONS.RATINGS_TABS) && <Tab>{t('page_content.human_resources.ratings_tab')}</Tab>}
            {shouldDisplayTab('Control panel', SECTIONS.CONTROL_PANEL_TABS) && <Tab>{t('page_content.human_resources.control_panel.parent_tab_control_panel')}</Tab>}
          </TabList>

          {shouldDisplayTab('Workers', SECTIONS.WORKERS_TABS) &&
            <TabPanel>
              <Workers department={department} />
            </TabPanel>}

          {shouldDisplayTab('Employments', SECTIONS.EMPLOYMENTS_TAB) &&
            <TabPanel>
              <Employments />
            </TabPanel>}

          {shouldDisplayTab('Departments', SECTIONS.DEPARTMENTS_TAB) &&
            <TabPanel>
              <DepartmentsTab handleShowWorkers={filterByDepartment} />
            </TabPanel>}

          {shouldDisplayTab('Shifts', SECTIONS.SHIFTS_TABS) &&
            <TabPanel>
              <Shifts />
            </TabPanel>}

          {shouldDisplayTab('Forms', SECTIONS.FORMS_TABS) &&
            <TabPanel>
              <Forms />
            </TabPanel>}

          {shouldDisplayTab('Assigned equipment', SECTIONS.ASSIGNED_EQUIPMENT_TABS) &&
            <TabPanel>
              <AssignedEquipment />
            </TabPanel>}

          {showWorkingInjuries && can(SECTIONS.WORKING_INJURIES_TAB, ACTIONS.VIEW) &&
            <TabPanel>
              <WorkingInjuries />
            </TabPanel>}

          {shouldDisplayTab('Ratings', SECTIONS.RATINGS_TABS) &&
            <TabPanel>
              <Ratings />
            </TabPanel>}

          {shouldDisplayTab('Control panel', SECTIONS.CONTROL_PANEL_TABS) &&
            <TabPanel>
              <ControlPanel />
            </TabPanel>}
        </Tabs>
      }
    </div>
  );
};

IndustryLocationHumanResources.propTypes = {
  hrTabs: PropTypes.array,
  t: PropTypes.func.isRequired,
  can: PropTypes.func.isRequired,
  showWorkingInjuries: PropTypes.bool,
  history: PropTypes.object.isRequired,
  roleLoading: PropTypes.bool.isRequired,
  determineUserRole: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    showWorkingInjuries: get(state, 'app.companyConfig.config.working_injuries', false),
    hrTabs: get(state, 'app.companyConfig.config.hr_tabs', null),
    roleLoading: get(state, 'role.loading', true),
    can: (section, action, useExceptions = false) => canPerformAction(state, section, action, useExceptions),
  };
}

const mapDispatchToProps = {
  determineUserRole: determineUserRoleAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(IndustryLocationHumanResources)));
