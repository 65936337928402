import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import moment from 'moment';
import { get } from 'lodash';

import { IconPopup, IconPreviewDocument, IconSearch } from 'shared/Icons';
import { Table, TableButtons, Button, DateFilters } from 'shared';
import { ordersCreatedAndUpdated } from 'shared/constants';
import { styledFormStatusOptions } from 'industry/helpers';
import { selectStyles } from 'styles/modules/reactSelect';

import { getAllWorkers, getFilledForms, getPaginatedFilledForms } from './actions';

import ViewFilledFormModal from './ViewFilledFormModal';

const FilledForms = ({ t, companyId, locationId }) => {
  const debounceTimeoutRef = useRef(null);
  const statusOptions = [
    { id: 'submitted', label: t('page_content.human_resources.forms.status_submitted') },
    { id: 'on_hold', label: t('page_content.human_resources.forms.status_on_hold') },
    { id: 'rejected', label: t('page_content.human_resources.forms.status_rejected') },
    { id: 'granted', label: t('page_content.human_resources.forms.status_granted') },
    { id: 'finished', label: t('page_content.human_resources.forms.status_finished') },
  ];
  const [tableDataFilled, setTableDataFilled] = useState({
    forms: [],
    count: null,
    previous: null,
    next: null,
    isLoading: true,
  });
  const [filledFormData, setFilledFormData] = useState({});
  const [showFilledFormModal, setShowFilledFormModal] = useState(false);
  const [sortData, setSortData] = useState({ desc: true, id: 'updated_at' });
  const [query, setQuery] = useState(null);
  const [dateFilter, setDateFilter] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [workers, setWorkers] = useState([]);
  const [clearDateFilters, setClearDateFilters] = useState(false);

  const fetchFilledForms = async () => {
    setTableDataFilled((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    let filters = '';

    if (sortData) {
      const asc = sortData.desc ? '-' : '';
      let column = sortData.id;
      if (column === 'worker') {
        column = 'worker__name';
      }
      filters += `&order_by=${asc}${column || 'updated_at'}`;
    }

    if (query) {
      filters += `&name=${query}`;
    }
    if (dateFilter?.start && selectedFilter?.date) {
      filters += `&${selectedFilter.date}_after=${moment(dateFilter.start).format('YYYY-MM-DD')}`;
    }
    if (dateFilter?.start && dateFilter?.end && selectedFilter?.date) {
      filters += `&${selectedFilter.date}_before=${moment(dateFilter.end).format('YYYY-MM-DD')}`;
    }

    if (selectedFilter?.status && selectedFilter?.status?.id !== 'finished') {
      filters += `&action_status=${selectedFilter.status.id}&is_finished=false`;
    } else if (selectedFilter?.status && selectedFilter?.status?.id === 'finished') {
      filters += '&is_finished=true';
    }

    if (selectedFilter?.worker) {
      filters += `&worker=${selectedFilter.worker.id}`;
    }

    const res = await getFilledForms(companyId, filters);
    setTableDataFilled({
      forms: get(res, 'data.results', []),
      count: get(res, 'data.count'),
      next: get(res, 'data.next'),
      previous: get(res, 'data.previous'),
      isLoading: false,
    });
  };

  const fetchPaginatedFilledForms = async (url) => {
    setTableDataFilled((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    const res = await getPaginatedFilledForms(url);
    setTableDataFilled({
      forms: get(res, 'data.results', []),
      count: get(res, 'data.count'),
      next: get(res, 'data.next'),
      previous: get(res, 'data.previous'),
      isLoading: false,
    });
  };

  const fetchWorkers = async () => {
    const res = await getAllWorkers(locationId, companyId);
    setWorkers(get(res, 'data.results', []));
  };

  useEffect(() => {
    fetchWorkers();
  }, []);

  const handleOpenButton = (e, row) => {
    e.stopPropagation();
    setFilledFormData(row?.original);
    setShowFilledFormModal(true);
  };

  const handleCloseFilledFormModal = () => {
    setFilledFormData(null);
    setShowFilledFormModal(false);
  };

  const handlePreviewFilledForm = (e, row) => {
    e.stopPropagation();
    const currentUrl = window.origin;
    const url = `${currentUrl}/surveys/worker-survey/${row?.original?.id}`;
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    if (query !== null) {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }

      debounceTimeoutRef.current = setTimeout(() => {
        fetchFilledForms();
      }, 300);
    }
  }, [query]);

  useEffect(() => {
    fetchFilledForms();
  }, [selectedFilter, dateFilter, sortData]);

  const handleSorting = (sortingData) => {
    setSortData({ desc: sortingData.desc, id: sortingData.id });
  };

  const handleFilterChange = (value, key) => {
    setSelectedFilter((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const clearAllFilters = () => {
    setDateFilter(null);
    setQuery('');
    setClearDateFilters((prevState) => !prevState);
    setSelectedFilter(null);
  };

  const handleSelectedDateFilter = (filter, start, end) => {
    setSelectedFilter((prevState) => ({
      ...prevState,
      date: filter,
    }));
    setDateFilter({
      start, end,
    });
  };

  const tableColumnsFilled = [
    {
      Header: () => <span>{t('page_content.human_resources.forms.table_column_filled_forms_name')}</span>,
      accessor: 'survey',
      Cell: (row) => (row && row.value ? row.value.name : '-'),
    },
    {
      Header: () => <span>{t('page_content.human_resources.forms.table_column_filled_forms_preview')}</span>,
      width: 100,
      sortable: false,
      Cell: (row) => (<div style={{ display: 'flex', justifyContent: 'center' }}>
      <Button style={{ width: 55, padding: '5px 8px' }} onClick={(e) => handleOpenButton(e, row)}>
        <IconPopup
          height="14px"
          width="14px"
          fill="#4285F4"
        />
      </Button>
    </div>),
    },
    {
      Header: () => <span>PDF</span>,
      width: 100,
      sortable: false,
      Cell: (row) => (<div style={{ display: 'flex', justifyContent: 'center' }}>
      <Button style={{ width: 55, padding: '5px 8px' }} onClick={(e) => handlePreviewFilledForm(e, row)}>
        <IconPreviewDocument
          height="14px"
          width="14px"
          fill="#4285F4"
        />
      </Button>
    </div>),
    },
    {
      Header: () => <span>{t('page_content.human_resources.forms.table_column_filled_forms_filled_by')}</span>,
      accessor: 'worker',
      Cell: (row) => (row && row.value ? `${row.value.name} ${row.value.last_name}` : '-'),
      style: {
        cursor: 'default',
      },
    },
    {
      Header: () => <span>{t('page_content.human_resources.forms.table_column_filled_forms_created_at')}</span>,
      accessor: 'created_at',
      Cell: (row) => (row && row.value ? moment(row.value).format(ordersCreatedAndUpdated) : '-'),
      style: {
        cursor: 'default',
      },
    },
    {
      Header: () => <span>{t('page_content.human_resources.forms.table_column_filled_forms_last_preview')}</span>,
      accessor: 'updated_at',
      Cell: (row) => (row && row.value ? moment(row.value).format(ordersCreatedAndUpdated) : '-'),
      style: {
        cursor: 'default',
      },
    },
    {
      Header: () => <span>{t('page_content.human_resources.forms.table_column_form_templates_status')}</span>,
      accessor: 'action_status',
      width: 150,
      Cell: (row) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <span style={row?.original?.is_finished && !row?.original?.survey?.action_required ? styledFormStatusOptions('finished') : row?.value ? styledFormStatusOptions(row?.value) : {}}>
            {row?.original?.is_finished && !row?.original?.survey?.action_required ? t('page_content.human_resources.forms.status_finished') : row?.value ? t([`page_content.human_resources.forms.status_${row.value}`]) : '-'}
          </span>
        </div>
      ),
      style: {
        cursor: 'default',
      },
    },
    {
      Header: () => <span>{t('page_content.human_resources.forms.table_column_filled_forms_description')}</span>,
      accessor: 'survey',
      Cell: (row) => (row && row.value ? row.value.description : '-'),
      style: {
        cursor: 'default',
      },
    },
  ];

  return (
    <div>
       <div className="filters_container">
        <div className="filters">
          <div className="input_container_search">
            <div className="input_field_style">
              <input onChange={(e) => setQuery(e.target.value)} placeholder={t('page_content.human_resources.forms.search_by_name')} value={query || ''} />
              {query && <button
                onClick={() => setQuery('')}
              >&times;</button>}
            </div>
          <div className="icon_container">
            <IconSearch
              color="#555"
              height="26px"
              width="26px"
            />
            </div>
          </div>
          <div>
            <Select
              className="select-style"
              isClearable
              options={statusOptions}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.id}
              placeholder={t('page_content.human_resources.forms.filter_by_status')}
              onChange={(e) => handleFilterChange(e, 'status')}
              value={selectedFilter?.status || ''}
              styles={selectStyles}
            />
          </div>
          <div>
            <Select
              className="select-style"
              isClearable
              options={workers}
              getOptionLabel={(option) => `${option.name} ${option.last_name}`}
              getOptionValue={(option) => option.id}
              placeholder={t('page_content.human_resources.forms.filter_by_worker')}
              onChange={(e) => handleFilterChange(e, 'worker')}
              value={selectedFilter?.worker || ''}
              styles={selectStyles}
            />
          </div>
          </div>
          <div className="filters">
          <DateFilters
            selectedFilterProp={handleSelectedDateFilter}
            filterOptions={[
              { label: t('page_content.human_resources.forms.table_column_form_templates_created_at'), id: 'created_at' },
              { label: t('page_content.human_resources.forms.table_column_filled_forms_last_preview'), id: 'updated_at' }]}
            clearFilters={clearDateFilters}
          />
          <div className="clear_button">
              <Button
                type="plain"
                onClick={clearAllFilters}
              >
                {t('page_content.orders.clear_all_button')}
              </Button>
            </div>
          </div>
          </div>
        <Table
          style={{ userSelect: 'text' }}
          columns={tableColumnsFilled}
          data={tableDataFilled?.forms || []}
          loading={tableDataFilled.isLoading}
          noDataText=" "
          showPagination={false}
          minRows={0}
          defaultPageSize={15}
          selectedRow={null}
          defaultSorted={[{ id: 'updated_at', desc: true }]}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
        />
  <TableButtons fetchFunction={fetchPaginatedFilledForms} next={tableDataFilled.next} previous={tableDataFilled.previous} count={tableDataFilled.count} />
  <ViewFilledFormModal
    fetchFilledForms={fetchFilledForms}
    formData={filledFormData}
    showModal={showFilledFormModal}
    handleClose={handleCloseFilledFormModal}
    companyId={companyId}
  />
  </div>
  );
};

FilledForms.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  return {
    companyId: get(state, 'app.company.id', null),
    locationId: get(state, 'app.location.id', null),
  };
};

export default connect(mapStateToProps)(withRouter(withTranslation()(FilledForms)));
