import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { Button } from 'shared';
import '../../../styles.scss';

import { ACTIONS } from 'industry/role/definitions/actions';
import { SECTIONS } from 'industry/role/definitions/sections';
import { canPerformAction } from 'industry/role/selectors';

import IndividualGoalsRating from './IndividualGoalsRating';
import CompanyValuesRating from './CompanyValuesRating';
import WorkProcessesRating from './WorkProcessesRating';
import PersonnelDataRating from './PersonnelDataRating';

const RatingScreen = ({ t, selectedWorker, handleBackButton, can }) => {
  return (
    <div className="ratings__screen">
      <p className="ratings__screen__worker">{`${selectedWorker?.name} ${selectedWorker?.last_name}`}</p>
      <Tabs>
        <TabList>
          {can(SECTIONS.RATINGS_TABS__INDIVIDUAL_GOALS_TAB, ACTIONS.VIEW) && <Tab>{t('page_content.human_resources.rating.individual_goals')}</Tab>}
          {can(SECTIONS.RATINGS_TABS__COMPANY_VALUES_TAB, ACTIONS.VIEW) && <Tab>{t('page_content.human_resources.rating.company_values')}</Tab>}
          <Tab>{t('page_content.human_resources.rating.work_processes')}</Tab>
          {can(SECTIONS.RATINGS_TABS__PERSONNEL_DATA_TAB, ACTIONS.VIEW) && <Tab>{t('page_content.human_resources.rating.personnel_data')}</Tab>}
        </TabList>

        <Button onClick={handleBackButton}>{t('page_content.human_resources.rating.back')}</Button>

        {can(SECTIONS.RATINGS_TABS__INDIVIDUAL_GOALS_TAB, ACTIONS.VIEW) && <TabPanel>
            <IndividualGoalsRating selectedWorker={selectedWorker} />
        </TabPanel>}

        {can(SECTIONS.RATINGS_TABS__COMPANY_VALUES_TAB, ACTIONS.VIEW) && <TabPanel>
            <CompanyValuesRating selectedWorker={selectedWorker} />
        </TabPanel>}

        <TabPanel>
            <WorkProcessesRating selectedWorker={selectedWorker} />
        </TabPanel>

        {can(SECTIONS.RATINGS_TABS__PERSONNEL_DATA_TAB, ACTIONS.VIEW) && <TabPanel>
            <PersonnelDataRating selectedWorker={selectedWorker} />
        </TabPanel>}
      </Tabs>
    </div>
  );
};

RatingScreen.propTypes = {
  t: PropTypes.func,
  can: PropTypes.func.isRequired,
  selectedWorker: PropTypes.object.isRequired,
  handleBackButton: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  can: (section, action, useExceptions = false) => canPerformAction(state, section, action, useExceptions),
});

export default connect(mapStateToProps)(withTranslation()(RatingScreen));
