import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import ControlPanelActions from './components/ControlPanelActions';
import ScoringCategories from './components/ScoringCategories';
import IndividualGoals from './components/IndividualGoals';
import CompanyValues from './components/CompanyValues';

const ControlPanel = ({ t }) => {
  return (
    <Tabs>
      <TabList>
        <Tab>{t('page_content.human_resources.control_panel.tab_category_scoring')}</Tab>
        <Tab>{t('page_content.human_resources.control_panel.tab_individual_goals')}</Tab>
        <Tab>{t('page_content.human_resources.control_panel.tab_kfk_values')}</Tab>
        <Tab>{t('page_content.human_resources.control_panel.tab_actions')}</Tab>
      </TabList>

      <TabPanel>
          <ScoringCategories />
      </TabPanel>

      <TabPanel>
          <IndividualGoals />
      </TabPanel>

      <TabPanel>
          <CompanyValues />
      </TabPanel>

      <TabPanel>
        <ControlPanelActions />
      </TabPanel>
  </Tabs>
  );
};

ControlPanel.propTypes = {
  t: PropTypes.func.isRequired,
};

export default (withTranslation()(ControlPanel));
