import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';

import { Button, Table, TableButtons, ConfirmationModal } from 'shared';
import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';
import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';
import { IconSearch } from 'shared/Icons';
import './style.scss';

import {
  getByURL,
  getProductTypes,
  postProductType,
  putProductType,
  deleteProductType,
  getProductGroups,

  getDepartments,
  getProductTemplates,
  getOrderWarehouses,
} from 'shared/Api';

import { checkAccessOnPage, redirectToSettingsPage } from 'industry/helpers';

import ProductTypeForm from './components/ProductTypeForm/index';

class ProductTypesTab extends Component {
  constructor(props) {
    super(props);
    this.timerRef = React.createRef();
    this.state = {
      isProductFormOpen: false,
      selectedProductType: null,
      isLoadingProducts: true,
      productTypes: [],
      productDepartments: [],
      productGroupsOptions: [],
      productDataTemplate: {},
      isSavingProduct: false,
      next: null,
      previous: null,
      productCodeQuery: '',
      productNameQuery: '',
      count: 0,
      selectedSort: 'name',
      selectedAscDesc: 'asc',
      deleteData: null,
      showConfirmationDialog: false,
    };
  }

  componentDidMount() {
    const { companyId, locationId } = this.props;

    checkAccessOnPage(companyId)
      .then((access) => {
        if (access === 0) redirectToSettingsPage(companyId, locationId);
        else if (access === 1) this.setState({ isReadOnly: true });
      });

    this.fetchWarehouses(locationId);
    this.fetchProductDepartments(locationId);
    this.fetchProductTypes();
    this.fetchProductDataTemplate(companyId);
    this.fetchProductGroups(locationId);
  }

  fetchProductGroups = async (locationId) => {
    const res = await getProductGroups(`?location=${locationId}&limit=9999&order_by=name`);
    this.setState({ productGroupsOptions: get(res, 'data.results', []) });
  }

  fetchProductTypes = () => {
    const { selectedAscDesc, selectedSort, productCodeQuery, productNameQuery } = this.state;
    const { companyId, locationId } = this.props;

    this.setState({ isLoadingProducts: true });

    let filters = `?company=${companyId}`;

    const asc = selectedAscDesc === 'desc' ? '-' : '';
    filters += `&order_by=${asc}${selectedSort}`;

    if (productCodeQuery) filters += `&code_contains=${productCodeQuery}`;
    if (productNameQuery) filters += `&name_contains=${productNameQuery}`;
    if (locationId) filters += `&location_or_none=${locationId}`;

    getProductTypes(filters)
      .then((resp) => {
        this.setState({
          productTypes: get(resp, 'data.results', []),
          count: get(resp, 'data.count', 0),
          next: get(resp, 'data.next', null),
          previous: get(resp, 'data.previous', null),
          isLoadingProducts: false,
        });
      });
  }

  fetchPaginatedProductTypes = (url) => {
    this.setState({
      isLoadingProducts: true,
    });
    getByURL(url)
      .then((re) => {
        this.setState({
          productTypes: get(re, 'data.results', []),
          count: get(re, 'data.count', 0),
          next: get(re, 'data.next', null),
          previous: get(re, 'data.previous', null),
          isLoadingProducts: false,
        });
      });
  }

  fetchProductDepartments = (locationId) => {
    const apiFilters = `?location=${locationId}&limit=999&order_by=name`;
    getDepartments(apiFilters)
      .then((resp) => this.setState({ productDepartments: get(resp, 'data.results', []) }));
  }

  fetchWarehouses = (locationId) => {
    const apiFilters = `?location=${locationId}&limit=999&order_by=name`;
    getOrderWarehouses(apiFilters)
      .then((resp) => {
        this.setState({
          warehouses: get(resp, 'data.results'),
        });
      });
  }

  fetchProductDataTemplate = (companyId) => {
    const apiFilters = `?company=${companyId}`;
    getProductTemplates(apiFilters)
      .then((resp) => this.setState({ productDataTemplate: get(resp, 'data.results[0].template', {}) }));
  }

  openProductTypeForm = () => {
    this.setState({ isProductFormOpen: true });
  }

  handleProductTableRowClick = (pType) => {
    const { isReadOnly } = this.state;
    if (!isReadOnly) {
      this.setState({
        selectedProductType: pType,
      }, () => {
        this.openProductTypeForm();
      });
    }
  }

  closeProductFormModal = () => {
    this.setState({
      isProductFormOpen: false,
      selectedProductType: null,
    });
  }

  handleEditProduct = (data) => {
    const { companyId } = this.props;

    this.setState({ isLoadingProducts: true });

    putProductType(data.id, `?company=${companyId}`, data)
      .then(() => {
        this.setState({
          isProductFormOpen: false,
          isLoadingProducts: false,
          selectedProductType: null,
        }, () => {
          this.fetchProductTypes();
        });
      });
  }

  handleSaveProductType = (data) => {
    this.setState({ isLoadingProducts: true });

    postProductType(data)
      .then(() => {
        this.setState({
          isProductFormOpen: false,
          isLoadingProducts: false,
          selectedProductType: null,
        }, () => {
          this.fetchProductTypes();
        });
      });
  }

  handleSorting = (sortData) => {
    const column = sortData.id;

    this.setState({
      selectedSort: column,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
    }, () => {
      this.fetchProductTypes();
    });
  }

  handleQueryOrFilterChange = (key, value) => {
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }), () => {
      this.handleStateQueryChange();
    });
  }

  handleQueryClear = (key) => {
    this.setState((prevState) => ({
      ...prevState,
      [key]: '',
    }), () => {
      this.handleStateQueryChange();
    });
  }

  handleStateQueryChange = () => {
    if (this.timerRef.current) {
      clearTimeout(this.timerRef.current);
      this.timerRef.current = undefined;
    }
    this.timerRef.current = setTimeout(() => {
      this.timerRef.current = undefined;
      this.fetchProductTypes();
    }, 600);
  }

  handleShowConfirmationModal = (original) => {
    this.setState({ showConfirmationDialog: true, deleteData: original });
  };

  handleDeleteProductType = () => {
    const { companyId } = this.props;

    this.setState({ isLoadingProducts: true });

    const row = this.state.deleteData;
    deleteProductType(row.id, `?company=${companyId}`)
      .then(() => this.fetchProductTypes())
      .finally(() => this.setState({ showConfirmationDialog: false }));
  };

  render() {
    const { companyId, t } = this.props;

    const {
      productTypes,
      isLoadingProducts,
      isProductFormOpen,
      selectedProductType,
      isReadOnly,
      next,
      previous,
      productDepartments,
      productDataTemplate,
      productCodeQuery,
      productNameQuery,
      productGroupsOptions,
      count,
      warehouses,
    } = this.state;

    const productTypeOptions = [
      { id: 'bom', name: t('settings.product_types.product_type_options.bom') },
      { id: 'product', name: t('settings.product_types.product_type_options.product') },
      { id: 'assembly', name: t('settings.product_types.product_type_options.assembly') },
      { id: 'part', name: t('settings.product_types.product_type_options.part') },
    ];

    const categoryOptions = [
      { id: 'commercial', name: t('settings.product_types.category_options.commercial') },
      { id: 'assembly', name: t('settings.product_types.category_options.assembly') },
      { id: 'product', name: t('settings.product_types.category_options.product') },
    ];

    const returnTechnologiesFromDepartment = (idToFind, objectList) => ((obj) => obj && obj.technologies)(objectList.find((obj) => obj.id === idToFind));

    const getDepartmentFromId = (idToFind, objectList) => {
      const foundObject = objectList.find((obj) => obj.id === parseInt(idToFind, 10));
      return foundObject ? foundObject.name : null;
    };

    const getWarehouseFromId = (idToFind, objectList) => {
      const foundObject = objectList.find((obj) => obj.id === parseInt(idToFind, 10));
      return foundObject ? foundObject.name : null;
    };

    // const getUnitFromId = (idToFind, objectList) => {
    //   const foundObject = objectList.find(obj => obj.id === parseInt(idToFind, 10));
    //   return foundObject ? foundObject.unit : null;
    // };

    const queryOptions = [
      { queryKey: 'productNameQuery', value: productNameQuery, placeholder: t('settings.product_types.table_column_name') },
      { queryKey: 'productCodeQuery', value: productCodeQuery, placeholder: t('settings.product_types.table_column_code') },
    ];

    return (
      <div className="product-types-container">
        <div className="toolbar_area">
          {queryOptions.map((query) => (
            <div key={query.queryKey} className="input_container">
              <input onChange={(e) => this.handleQueryOrFilterChange(query.queryKey, e.target.value)} placeholder={query.placeholder} value={query.value} />
              {query.value && <button
                onClick={() => {
                  this.handleQueryClear(query.queryKey);
                }}
              >&times;</button>}
              <div className="icon_container">
                <IconSearch
                  color="#555"
                  height="26px"
                  width="26px"
                />
              </div>
            </div>
          ))}
          <div className="button_wrapper">
            <Button
              onClick={this.openProductTypeForm}
              disabled={isReadOnly}
              type="add"
            >
              {t('settings.product_types.add_new_product_type_button')}
            </Button>
          </div>

        </div>

        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('settings.product_types.table_column_code')}</span>,
              accessor: 'code',
              style: { cursor: 'default' },
            },
            {
              Header: () => <span>{t('settings.product_types.table_column_name')}</span>,
              accessor: 'name',
              style: { cursor: 'default' },
            },
            {
              Header: () => <span>{t('settings.product_types.table_column_unit')}</span>,
              accessor: 'base_unit',
              width: 100,
              Cell: (row) => <span>{row.value || ''}</span>,
              style: { cursor: 'default' },
            },
            {
              Header: () => <span>{t('settings.product_types.table_column_description')}</span>,
              accessor: 'description',
              style: { cursor: 'default' },
            },
            {
              Header: () => <span>{t('settings.product_types.default_warehouse')}</span>,
              accessor: 'default_warehouse',
              Cell: (row) => <span>{row.value ? getWarehouseFromId(row?.value, warehouses) : ''}</span>,
              style: { cursor: 'default' },
            },
            {
              Header: () => <span>{t('settings.product_types.table_column_department')}</span>,
              accessor: 'department',
              width: 150,
              Cell: (row) => <span>{row.value ? getDepartmentFromId(row.value, productDepartments) : ''}</span>,
              style: { cursor: 'default' },
            },
            {
              Header: () => <span>{t('settings.product_types.table_column_technology')}</span>,
              accessor: 'technology',
              width: 150,
              Cell: (row) => {
                const technologies = returnTechnologiesFromDepartment(row.original.department, productDepartments);
                if (technologies && technologies.length > 0) {
                  const technology = technologies.find((tech) => tech.id === row.value);
                  if (technology) return <span>{technology.name}</span>;
                }
                return '';
              },
              style: { cursor: 'default' },
            },
            {
              Header: () => <span>{t('settings.product_types.table_column_productType')}</span>,
              accessor: 'product_type',
              width: 100,
              Cell: (row) => {
                const productTypeName = row.value
                  ? productTypeOptions.find((pt) => pt.id === row.value).name
                  : '';
                return <span>{productTypeName}</span>;
              },
              style: { cursor: 'default' },
            },
            {
              Header: () => <span>{t('settings.product_types.table_column_category')}</span>,
              accessor: 'category',
              width: 100,
              Cell: (row) => {
                const productTypeName = row.value
                  ? categoryOptions.find((pt) => pt.id === row.value).name
                  : '';
                return <span>{productTypeName}</span>;
              },
              style: { cursor: 'default' },
            },
            {
              Header: () => <span>{t('settings.product_types.table_column_group')}</span>,
              accessor: 'group',
              width: 125,
              Cell: (row) => {
                const productGroupName = row?.value && productGroupsOptions.length > 0 ? productGroupsOptions.find((pt) => pt.id === row.value).name : '';
                return <span>{productGroupName}</span>;
              },
              style: { cursor: 'default' },
            },
            {
              Header: () => <span>{t('settings.product_types.is_scrap')}</span>,
              accessor: 'is_scrap',
              Cell: (row) => (
                <div style={{ margin: 'auto' }}>
                  {row?.value
                    ? <img src={checkMarkTrue} width="25px" height="20px" alt="" />
                    : <img src={checkMarkFalse} width="25px" height="20px" alt="" />}
                </div>
              ),
              style: { alignItems: 'center', cursor: 'default' },
              width: 115,
            },
          ]}
          data={productTypes}
          loading={isLoadingProducts}
          minRows={0}
          noDataText=" "
          sortable
          defaultPageSize={30}
          enableEdit
          enableDelete
          onEdit={(original) => this.handleProductTableRowClick(original)}
          onDelete={(original) => this.handleShowConfirmationModal(original)}
          defaultSorted={[{ id: 'name', desc: false }]}
          onSortedChange={(newSorted) => { this.handleSorting(newSorted[0]); }}
        />
        <TableButtons next={next} previous={previous} fetchFunction={this.fetchPaginatedProductTypes} count={count} />

        <ConfirmationModal
          itemName={this.state.deleteData?.name || ''}
          showModal={this.state.showConfirmationDialog}
          handleCloseModal={() => this.setState({ showConfirmationDialog: false })}
          handleConfirmModal={this.handleDeleteProductType}
          type="warning"
        />

        {
          isProductFormOpen && <ProductTypeForm
            isOpen={isProductFormOpen}
            closeModal={this.closeProductFormModal}
            pType={selectedProductType || null}
            companyId={companyId}
            saveProductType={selectedProductType ? this.handleEditProduct : this.handleSaveProductType}
            productDepartments={productDepartments}
            returnTechnologies={returnTechnologiesFromDepartment}
            productTypeOptions={productTypeOptions}
            categoryOptions={categoryOptions}
            productGroupsOptions={productGroupsOptions}
            productDataTemplate={productDataTemplate}
            loading={isLoadingProducts}
            warehouses={warehouses}
          />
        }
      </div>
    );
  }
}

ProductTypesTab.propTypes = {
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ProductTypesTab);
