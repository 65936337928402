import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Table, TableButtons, Button, ConfirmationModal } from 'shared';

import { getValueOptions, getPaginatedValueOptions, deleteValueOption } from '../../../actions';
import '../styles.scss';
import ValueOptionsModal from './ValueOptionsModal';

const ValueOptionsTable = ({ t, companyId }) => {
  const [valueOptions, setValueOptions] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: true,
  });
  const [valueOptionsFilters, setValueOptionsFilters] = useState({
    selectedAscDesc: 'desc',
    selectedSort: 'score',
  });

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [modalData, setModalData] = useState({
    isOpen: false,
    selectedItem: null,
  });

  const fetchValueOptions = () => {
    setValueOptions((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const asc = valueOptionsFilters?.selectedAscDesc === 'desc' ? '-' : '';
    const apiFilters = `&limit=15&order_by=${asc}${valueOptionsFilters?.selectedSort}`;

    getValueOptions(companyId, apiFilters)
      .then((res) => {
        setValueOptions({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setValueOptions((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchPaginatedValueOptions = (url) => {
    setValueOptions((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getPaginatedValueOptions(url)
      .then((res) => {
        setValueOptions({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setValueOptions((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  useEffect(() => {
    fetchValueOptions();
  }, [companyId, valueOptionsFilters]);

  const handleShowConfirmationDialog = (row) => {
    setShowConfirmationDialog(true);
    setDeleteData(row);
  };

  const handleDeleteItem = async () => {
    await deleteValueOption(deleteData?.id, companyId);
    setShowConfirmationDialog(false);
    fetchValueOptions();
  };

  const handleSorting = (sortData) => {
    let sortKey = sortData.id;

    if (sortKey === 'score.name') sortKey = 'score';
    if (sortKey === 'value.name') sortKey = 'value';

    setValueOptionsFilters((prevState) => ({
      ...prevState,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
      selectedSort: sortKey,
    }));
  };

  const handleTableRowClick = (row) => {
    setModalData({
      isOpen: true,
      selectedItem: row,
    });
  };

  const handleAddNewValueOption = () => {
    setModalData({
      isOpen: true,
      selectedItem: null,
    });
  };

  const handleCloseModal = () => {
    setModalData({
      isOpen: false,
      selectedItem: null,
    });
  };

  const closeModalAndRefetch = () => {
    handleCloseModal();
    fetchValueOptions();
  };

  return (
    <React.Fragment>
      <div className="company_values_table">
        <h3>{t('page_content.human_resources.control_panel.company_values.value_options')}</h3>

        <div className="table_action_buttons">
        <Button
          type="add"
          onClick={handleAddNewValueOption}
        >
          {t('page_content.human_resources.control_panel.company_values.add_new_value_options')}
        </Button>
        </div>

        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('page_content.human_resources.control_panel.company_values.name')}</span>,
              accessor: 'value.name',
            },
            {
              Header: () => <span>{t('page_content.human_resources.control_panel.company_values.score')}</span>,
              accessor: 'score.name',
              Cell: (row) => (<span>{`${row?.value} (${get(row, 'original.score.score')})`}</span>),
            },
            {
              Header: () => <span>{t('page_content.human_resources.control_panel.company_values.description')}</span>,
              accessor: 'name',
            },
          ]}
          data={valueOptions.data || []}
          defaultPageSize={100}
          loading={valueOptions.isLoading}
          minRows={0}
          noDataText=""
          enableEdit
          enableDelete
          onEdit={(row) => handleTableRowClick(row)}
          onDelete={(row) => handleShowConfirmationDialog(row)}
          showPagination={false}
          selectedRow={null}
          defaultSorted={[{ id: 'score.name', desc: true }]}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
        />
        <div>
          <span style={{ float: 'right' }}>
            <TableButtons
              previous={valueOptions.previous}
              next={valueOptions.next}
              fetchFunction={fetchPaginatedValueOptions}
              count={valueOptions.count}
            />
          </span>
        </div>
      </div>

      {modalData?.isOpen &&
      <ValueOptionsModal
        isOpen={modalData?.isOpen}
        initialValues={modalData?.selectedItem}
        handleCloseModal={handleCloseModal}
        closeModalAndRefetch={closeModalAndRefetch}
      />}

      <ConfirmationModal
        itemName={`${get(deleteData, 'name')}`}
        showModal={showConfirmationDialog}
        handleCloseModal={() => setShowConfirmationDialog(false)}
        handleConfirmModal={handleDeleteItem}
        type="warning"
      />
    </React.Fragment>
  );
};

ValueOptionsTable.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  return {
    companyId: get(state, 'app.company.id', null),
  };
};

export default connect(mapStateToProps)(withTranslation()(ValueOptionsTable));
