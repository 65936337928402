import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { defaultDateFormat, defaultISODateFormat } from 'shared/constants';
import { IconCloudDownload, IconPopup, IconShifts } from 'shared/Icons';
import view1 from 'shared/Icons/workforce/list-view-active.png';
import view2 from 'shared/Icons/workforce/grid-view-active.png';
import userGreen from 'shared/Icons/workforce/user-green.png';
import { ContentLoader } from 'shared';
import './styles.scss';

import { ACTIONS } from 'industry/role/definitions/actions';
import { SECTIONS } from 'industry/role/definitions/sections';
import { canPerformAction } from 'industry/role/selectors';
import { determineUserRole as determineUserRoleAction } from 'industry/role/roleActions';

import { getWorkerDetails, getWorkerDocuments } from './actions';
import { getWorkerVacations, getAbsence } from './components/AbsencesAndVacations/actions';

import ManageDocumentsModal from './components/ManageDocumentsModal/ManageDocumentsModal';
import AbsencesAndVacations from './components/AbsencesAndVacations/AbsencesAndVacations';
import PDFPreviewModal from '../IndustryProjectDetails/components/PDFPreviewModal';
import AllContractsModal from './components/AllContractsModal/AllContractsModal';
import WorkerWorkingPlaces from './components/WorkerWorkingPlaces/index';
import WorkingInjuriesTab from './components/WorkingInjuriesTab';
import WorkerSchedulesTab from './components/WorkerSchedulesTab';
import GeneralInfoTab from './components/GeneralInfoTab/index';
import MyForms from './components/MyForms/MyForms';
import ProfilePictureModal from './components/ProfilePictureModal';

const IndustryLocationWorkerDetails = ({ locationId, companyId, t, currentUser, showWorkingInjuries, determineUserRole, can, roleLoading }) => {
  const [workerId, setWorkerId] = useState();
  const [workerDetails, setWorkerDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDocumentationModal, setShowDocumentationModal] = useState(false);
  const [pdfPreviewModalState, setPdfPreviewModalState] = useState(false);
  const [pdfPreviewModalObject, setPdfPreviewModalObject] = useState(null);
  const [showAllContracts, setShowAllContracts] = useState(false);
  const [contractsTableData, setContractsTableData] = useState({
    contract: [],
    isLoading: false,
  });
  const [lastContract, setLastContract] = useState({});
  const [showAbsenceModal, setShowAbsenceModal] = useState(false);
  const [workerAbsencesAndVacations, setWorkerAbsencesAndVacations] = useState({
    usedDays: 0,
    assignedDays: 0,
    absencesCount: 0,
  });
  const [profilePictureModalState, setProfilePictureModalState] = useState(false);

  const fetchWorkerDetails = () => {
    setLoading(true);
    getWorkerDetails(locationId, companyId, workerId)
      .then((resp) => {
        const worker = get(resp, 'data', []);
        if (worker) {
          setWorkerDetails(worker);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
  };

  const fetchContracts = async () => {
    if (can(SECTIONS.WORKER_DETAILS_TAB__DOWNLOAD_CONTRACT_BTN, ACTIONS.VIEW)) {
      setContractsTableData((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      const filters = '&is_mid=true&order_by=-updated_at';
      const res = await getWorkerDocuments(companyId, workerId, filters);
      const contractsData = get(res, 'data.results', []);
      setLastContract(contractsData[0]);
      setContractsTableData({
        contracts: contractsData,
        isLoading: false,
      });
    }
  };

  const fetchWorkerVacationDays = async () => {
    await getWorkerVacations(companyId, workerId, `&year=${moment().format('YYYY')}&order_by=-updated_at`)
      .then((res) => {
        const usedDays = get(res, 'data.results[0].used_days', 0);
        const assignedDays = get(res, 'data.results[0].assigned_days', 0);
        setWorkerAbsencesAndVacations((prevState) => ({
          ...prevState,
          usedDays,
          assignedDays,
        }));
      });
  };

  const fetchWorkerAbsences = async () => {
    await getAbsence(companyId, workerId, `&limit=9999&order_by=-updated_at&absence_type=sick-leave&start_date=${moment().subtract(3, 'years').startOf('year').format(defaultISODateFormat)}&end_date=${moment().endOf('year').format(defaultISODateFormat)}`)
      .then((res) => {
        const absences = get(res, 'data.results', []);
        const totalDays = absences.reduce((acc, absence) => acc + (absence.number_of_days || 0), 0);

        setWorkerAbsencesAndVacations((prevState) => ({
          ...prevState,
          absencesCount: totalDays,
        }));
      });
  };

  const updateAbsenceAndVacationDays = () => {
    fetchWorkerVacationDays();
    fetchWorkerAbsences();
  };
  useEffect(() => {
    determineUserRole();
  }, [determineUserRole]);

  useEffect(() => {
    if (showAllContracts) fetchContracts();
  }, [showAllContracts]);

  useEffect(() => {
    if (workerId && currentUser) {
      fetchWorkerVacationDays();
      fetchWorkerAbsences();
    }
  }, [currentUser, workerId]);

  useEffect(() => {
    const getWorkerId = () => {
      const url = window.location.href;
      const urlParts = url.split('/');
      setWorkerId(parseInt(urlParts[urlParts.length - 1], 10));
    };

    if (!workerId) {
      getWorkerId();
    } else {
      fetchWorkerDetails();
    }
  }, [workerId]);

  const closePDFPreviewModal = () => {
    setPdfPreviewModalObject({});
    setPdfPreviewModalState(false);
  };

  const handleClickDownloadDocument = () => {
    const link = document.createElement('a');
    link.href = lastContract.file;
    link.target = '_blank';
    link.setAttribute('download', lastContract.name);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  const openProfilePictureModal = () => {
    setProfilePictureModalState(true);
  };

  if (loading && !workerId) {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <div className="loaderWrapper">
          <ContentLoader />
        </div>
      </div>
    );
  }

  const transformNameOrSurnameString = (string) => {
    if (!string) {
      return '';
    }
    const deconstructedString = string.split(/\s+|-/);
    const formatedString = deconstructedString.map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()).join(' ');

    return formatedString;
  };

  return (
    loading && roleLoading && workerDetails?.id ? <div style={{ width: '100%', alignContent: 'center' }}><ContentLoader onlySpinner /></div> : (
      <div className="workerDetails_container fullscreen">
        {
          can(SECTIONS.WORKER_DETAILS_TAB__BREADCRUMBS, ACTIONS.VIEW) &&
            <div className="breadcrumbs">
              <Link to={`/${companyId}/industry/location/${locationId}/human-resources/`} className="breadcrumbs__link">
                {t('page_content.workforce_management.worker_details.breadcrumb_workers_list')}
              </Link>
              <p>{'>'}</p>
              <span>{`${transformNameOrSurnameString(workerDetails.name)} ${transformNameOrSurnameString(workerDetails.last_name)}`}</span>
            </div>
        }

        <div className="info_row">
          <div className="info_card">
              <div style={{ display: 'flex', gap: 10 }}>
                <div className="leftColumn">
                  {
                    loading ? <ContentLoader width={100} height={100} onlySpinner /> :
                      workerDetails?.profile_picture?.full_size ? (
                        <img
                          alt=""
                          className="profile__picture"
                          src={workerDetails?.profile_picture?.full_size}
                          onClick={openProfilePictureModal}
                        />
                      ) : (
                        <img className="placeholder__picture" src={userGreen} alt="" />
                      )
                  }
                </div>
                {
                  workerDetails?.last_name && workerDetails?.name && (
                    <div className="rightColumn">
                      <div className="title">{`${workerDetails.name} ${workerDetails.last_name}`}</div>
                    </div>
                  )
                }
              </div>
          </div>

          <div className="info_card">
            <div className="column">
              <div className="inline">
                <div className="leftColumn">
                  <img src={view1} alt="" />
                </div>
                <div className="rightColumn">
                  <div className="title">{t('page_content.workforce_management.worker_details.card_work_contract')}</div>
                </div>
              </div>
              <div className="date">
              {lastContract?.created_at && `${t('page_content.workforce_management.worker_details.card_last_updated')}: ${moment(lastContract?.created_at).format(defaultDateFormat)}`}
              </div>
              <div className="icons">
                <div
                  className={`${!can(SECTIONS.WORKER_DETAILS_TAB__ALL_CONTRACTS_BTN, ACTIONS.VIEW) ? 'icon_button disabled_icon_button' : 'icon_button'}`}
                  onClick={() => can(SECTIONS.WORKER_DETAILS_TAB__ALL_CONTRACTS_BTN, ACTIONS.VIEW) && setShowAllContracts(true)}
                >
                  <IconPopup height="15px" width="15px" /> {t('page_content.workforce_management.worker_details.all_contracts_button')}
                </div>
                <div
                  className={`${(!can(SECTIONS.WORKER_DETAILS_TAB__DOWNLOAD_CONTRACT_BTN, ACTIONS.VIEW) || !lastContract) ? 'icon_button disabled_icon_button' : 'icon_button'}`}
                  onClick={() => { if (can(SECTIONS.WORKER_DETAILS_TAB__DOWNLOAD_CONTRACT_BTN, ACTIONS.VIEW) && lastContract) handleClickDownloadDocument(); }}
                >
                  <IconCloudDownload height="15px" width="15px" color={`${(can(SECTIONS.WORKER_DETAILS_TAB__DOWNLOAD_CONTRACT_BTN, ACTIONS.VIEW) && !lastContract) ? '#979797' : '#555'}`} /> {t('page_content.workforce_management.worker_details.download_document_button')}
                </div>
              </div>
            </div>
          </div>

          <div className="info_card">
            <div className="column">
              <div className="inline">
                <div className="leftColumn">
                  <img src={view2} alt="" />
                </div>
                <div className="rightColumn">
                  <div className="title">{t('page_content.workforce_management.worker_details.card_other_documentations')}</div>
                </div>
              </div>
              <div className="icons">
                <div
                  className={`${!can(SECTIONS.WORKER_DETAILS_TAB__OTHER_DOCUMENTATION_BTN, ACTIONS.VIEW) ? 'icon_button disabled_icon_button' : 'icon_button'}`}
                  onClick={() => can(SECTIONS.WORKER_DETAILS_TAB__OTHER_DOCUMENTATION_BTN, ACTIONS.VIEW) && setShowDocumentationModal(true)}
                >
                  <IconPopup height="15px" width="15px" color="#555" /> {t('page_content.workforce_management.worker_details.manage_documents_button')}
                </div>
              </div>
            </div>
          </div>

          <div className="info_card">
            <div className="column">
              <div className="inline">
                <div className="leftColumn" style={{ padding: 14 }}>
                  <IconShifts fill="#7bc7be" height="50px" width="50px" />
                </div>
                <div className="rightColumn">
                  <div className="description">
                    <span className="text">{t('page_content.workforce_management.worker_details.worker_absence_vacation_card.remaining_total_vacation')}:</span>
                    <span className="numbers">{`${workerAbsencesAndVacations?.usedDays || 0}/${workerAbsencesAndVacations?.assignedDays || 0}`}</span>
                  </div>
                  <div className="description">
                    <span className="text">{t('page_content.workforce_management.worker_details.worker_absence_vacation_card.sick_leave_last_three_years')}:</span>
                    <span className="numbers">{workerAbsencesAndVacations?.absencesCount}</span>
                  </div>
                </div>
              </div>
              <div className="icons">
                <div
                  className={`${!can(SECTIONS.WORKER_DETAILS_TAB__VIEW_ABSENCES_BTN, ACTIONS.VIEW) ? 'icon_button disabled_icon_button' : 'icon_button'}`}
                  onClick={() => can(SECTIONS.WORKER_DETAILS_TAB__VIEW_ABSENCES_BTN, ACTIONS.VIEW) && setShowAbsenceModal(true)}
                >
                  <IconPopup height="15px" width="15px" color="#555" /> {t('page_content.workforce_management.worker_details.worker_absence_vacation_card.button_view')}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="tab_pannels">
          <Tabs>

            <TabList>
              <Tab>{t('page_content.workforce_management.worker_details.tab_general')}</Tab>
              <Tab>{t('page_content.workforce_management.worker_details.tab_my_forms')}</Tab>
              {showWorkingInjuries && <Tab>{t('page_content.human_resources.injuries.working_injuries')}</Tab>}
              <Tab>{t('page_content.workforce_management.worker_details.worker_scheduler')}</Tab>
              <Tab>{t('page_content.workforce_management.worker_details.working_places_tab.working_places_tab_title')}</Tab>
            </TabList>

            <TabPanel>
              <GeneralInfoTab
                workerDetails={workerDetails}
                transformString={transformNameOrSurnameString}
                fetchWorkerDetails={fetchWorkerDetails}
              />
            </TabPanel>

            <TabPanel>
              <MyForms
                workerId={workerId}
              />
            </TabPanel>

            {
              showWorkingInjuries &&
                <TabPanel>
                  <WorkingInjuriesTab workerId={workerId} />
                </TabPanel>
            }

            <TabPanel>
              <WorkerSchedulesTab
                workerId={workerId}
                workerDetails={workerDetails}
              />
            </TabPanel>

            <TabPanel>
              <WorkerWorkingPlaces
                workerId={workerId}
                workerDetails={workerDetails}
              />
            </TabPanel>

          </Tabs>
        </div>

        {
          showAbsenceModal &&
            <AbsencesAndVacations
              workerId={workerId}
              showAbsenceModal={showAbsenceModal}
              handleClose={() => setShowAbsenceModal(false)}
              workerAbsencesAndVacations={workerAbsencesAndVacations}
              updateAbsenceAndVacationDays={updateAbsenceAndVacationDays}
            />
        }

        {
          showDocumentationModal &&
            <ManageDocumentsModal
              workerId={workerId}
              refetchDocumentTypes={fetchContracts}
              showDocumentationModal={showDocumentationModal}
              handleClose={() => setShowDocumentationModal(false)}
            />
        }
        {
          showAllContracts &&
            <AllContractsModal
              workerId={workerId}
              showModal={showAllContracts}
              tableData={contractsTableData}
              handleCloseModal={() => setShowAllContracts(false)}
            />
        }

        <PDFPreviewModal
          closeModal={closePDFPreviewModal}
          modalStatus={pdfPreviewModalState}
          pdfObjectInfo={pdfPreviewModalObject}
        />

        <ProfilePictureModal
          isOpen={profilePictureModalState}
          profilePicture={workerDetails?.profile_picture?.full_size}
          handleCloseModal={() => setProfilePictureModalState(false)}
        />
      </div>
    )
  );
};

IndustryLocationWorkerDetails.propTypes = {
  t: PropTypes.func.isRequired,
  can: PropTypes.func.isRequired,
  showWorkingInjuries: PropTypes.bool,
  companyId: PropTypes.number.isRequired,
  roleLoading: PropTypes.bool.isRequired,
  locationId: PropTypes.number.isRequired,
  currentUser: PropTypes.object.isRequired,
  determineUserRole: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: get(state, 'currentUser', null),
    roleLoading: get(state, 'role.loading', true),
    showWorkingInjuries: get(state, 'app.companyConfig.config.working_injuries', false),
    can: (section, action, useExceptions = false) => canPerformAction(state, section, action, useExceptions),
  };
};

const mapDispatchToProps = {
  determineUserRole: determineUserRoleAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(IndustryLocationWorkerDetails)));
