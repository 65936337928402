import { get } from 'lodash';

import { checkAccess, redirectToHomePage } from 'industry/helpers';

import ROLE_CONFIG from 'industry/role/definitions/config/roleConfig';

import api from 'helpers/api';

import {
  SET_ROLE_START,
  SET_ROLE_SUCCESS,
  SET_ROLE_FAILURE,
  CLEAR_ROLE,
} from './actionTypes';

export const setRoleStart = () => ({
  type: SET_ROLE_START,
});

export const setRoleSuccess = (roleData) => ({
  type: SET_ROLE_SUCCESS,
  payload: roleData,
});

export const setRoleFailure = (error) => ({
  type: SET_ROLE_FAILURE,
  payload: error,
});

export const clearRole = () => ({
  type: CLEAR_ROLE,
});

export const determineUserRole = () => async (dispatch, getState) => {
  const state = getState();
  const prevRole = get(state, 'role', null);
  const currentUser = get(state, 'currentUser', null);
  const companyId = get(state, 'app.company.id', null);
  const locationId = get(state, 'app.location.id', null);

  if (!currentUser || !companyId || !locationId || (prevRole?.currentRole && !prevRole?.error)) {
    return;
  }

  dispatch(setRoleStart());

  try {
    if (!currentUser) {
      dispatch(setRoleFailure('No user provided'));
      return;
    }

    if (currentUser?.is_admin) {
      dispatch(setRoleSuccess({
        currentRole: 'Admin',
        roleConfig: ROLE_CONFIG.ADMIN,
      }));
      return;
    }

    if (companyId && locationId) {
      // HR Admin check
      const isHrAdmin = get(await checkAccess('x-hr-admin', companyId), 'data.access', 0) === 10;
      if (isHrAdmin) {
        dispatch(setRoleSuccess({
          currentRole: 'HR Admin',
          roleConfig: ROLE_CONFIG.HR_ADMIN,
        }));
        return;
      }

      // HR Specialist check
      const isHrSpecialist = get(await checkAccess('x-hr-specialist', companyId), 'data.access', 0) === 10;
      if (isHrSpecialist) {
        dispatch(setRoleSuccess({
          currentRole: 'HR Specialist',
          roleConfig: ROLE_CONFIG.HR_SPECIALIST,
        }));
        return;
      }

      // HR Role check
      const hrRole = currentUser?.roles.find((obj) => obj.role?.name?.toLowerCase() === 'hr');
      if (hrRole) {
        dispatch(setRoleSuccess({
          currentRole: 'HR',
          roleConfig: ROLE_CONFIG.HR,
        }));
        return;
      }

      // Manager/Worker check
      const workerId = get(currentUser, 'worker_id', null);
      if (workerId) {
        const data = await api.get(`/api/v1/workforce/department_managers/?location=${locationId}&worker=${workerId}&is_active=true`);
        const results = get(data, 'data.results', null);
        const isManager = results?.some((manager) => manager.is_manager || manager.is_deputy_manager);

        if (isManager) {
          const managerDepartments = results?.filter((manager) => manager.is_manager || manager.is_deputy_manager)?.map((obj) => obj.department);

          dispatch(setRoleSuccess({
            currentRole: 'Manager',
            roleConfig: ROLE_CONFIG.MANAGER,
            workerId,
            managerDepartments,
          }));
          return;
        }

        dispatch(setRoleSuccess({
          currentRole: 'Worker',
          roleConfig: ROLE_CONFIG.WORKER,
          workerId,
        }));
        return;
      }
    }

    dispatch(setRoleFailure('Invalid role configuration'));
  } catch (error) {
    dispatch(setRoleFailure(error.message));
    redirectToHomePage(companyId, locationId);
  }
};
