import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import '../../styles.scss';

import AssignedEquipmentTab from './components/AssignedEquipmentTab';
import EquipmentCatalogueTab from './components/EquipmentCatalogueTab';

const AssignedEquipment = ({ t }) => {
  return (
    <div className="assigned_equipment">
      <Tabs>
        <TabList>
          <Tab>{t('page_content.human_resources.assigned_equipment_and_info.assigned_equipment_tab')}</Tab>
          <Tab>{t('page_content.human_resources.assigned_equipment_and_info.equipment_catalogue_tab')}</Tab>
        </TabList>

        <TabPanel>
          <AssignedEquipmentTab />
        </TabPanel>

        <TabPanel>
          <EquipmentCatalogueTab />
        </TabPanel>
      </Tabs>
    </div>
  );
};

AssignedEquipment.propTypes = {
  t: PropTypes.func.isRequired,
};

export default (withTranslation()(AssignedEquipment));
