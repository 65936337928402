import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';

import { Table, TableButtons } from 'shared';
import '../../styles.scss';

import { defaultDateFormat } from 'shared/constants';

import { getScoringDataDefinitions, getScoringWorkerData, getScoringCheckData, getByURL } from 'shared/Api';

const PersonnelDataRating = ({ t, companyId, selectedWorker }) => {
  const [personnelData, setPersonnelData] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: false,
  });
  const [additionalData, setAdditionalData] = useState({
    definitions: [],
    next: null,
    previous: null,
    count: null,
    isLoading: true,
  });

  const currentYear = moment().format('YYYY');
  const [filters, setFilters] = useState({
    selectedAscDesc: 'asc',
    selectedSort: 'order',
    year: null,
  });

  const [addedScore, setAddedScore] = useState([]);

  const fetchPersonnelDataRatings = async () => {
    setPersonnelData((prevState) => ({ ...prevState, isLoading: true }));

    let apiFilters = `?limit=9999&order_by=-updated_at&worker=${selectedWorker.id}&company=${companyId}`;

    if (filters?.year) apiFilters += `&year=${filters.year}&is_active=false`;
    else apiFilters += '&is_active=true';

    await getScoringWorkerData(apiFilters)
      .then((res) => {
        const result = get(res, 'data.results', []);
        setPersonnelData({
          data: result,
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      });
  };

  const fetchDataDefinitions = () => {
    setAdditionalData((prevState) => ({ ...prevState, isLoading: true }));

    const asc = filters?.selectedAscDesc === 'desc' ? '-' : '';
    const apiFilters = `?company=${companyId}&limit=15&is_active=true&order_by=${asc}${filters?.selectedSort}`;

    getScoringDataDefinitions(apiFilters)
      .then((res) => {
        setAdditionalData({
          definitions: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      });
  };

  const fetchPaginatedDataDefinitions = (url) => {
    setAdditionalData((prevState) => ({ ...prevState, isLoading: true }));

    getByURL(url)
      .then((res) => {
        setAdditionalData({
          definitions: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      });
  };

  const handleRefreshAutomaticValues = () => {
    setAdditionalData((prevState) => ({ ...prevState, isLoading: true }));
    const apiFilters = `?company=${companyId}&year=${currentYear}&worker=${selectedWorker.id}`;
    getScoringCheckData(apiFilters).then(() => fetchDataDefinitions());
  };

  useEffect(() => {
    handleRefreshAutomaticValues();
  }, [filters]);

  useEffect(() => {
    fetchPersonnelDataRatings();
  }, [additionalData.definitions]);

  useEffect(() => {
    if (additionalData?.definitions && personnelData?.data) {
      const newAddedScore = !filters?.year ?
        (
          additionalData?.definitions?.map((definition) => {
            const score = personnelData?.data?.find((item) => item?.definition?.id === definition.id);
            return {
              ...definition,
              definition_id: definition?.id,
              score_id: score?.id || null,
              score: score?.score || '-',
              year: score?.year || '-',
              score_updated_at: score?.updated_at || null,
              value: score?.value || '-',
            };
          })
        ) : (
          additionalData?.definitions?.flatMap((definition) => {
            const scores = personnelData?.data?.filter((item) => item?.definition?.id === definition.id);
            const score = scores?.map((score1) => {
              return {
                ...definition,
                definition_id: definition?.id,
                score_id: score1?.id || null,
                score: score1?.score || '-',
                year: score1?.year || '-',
                score_updated_at: score1?.updated_at || null,
                value: score1?.value || '-',
              };
            });
            return score.length ?
              score : {
                ...definition,
                definition_id: definition?.id,
                score_id: null,
                score: '-',
                year: '-',
                score_updated_at: null,
                value: '-',
              };
          })
        );
      setAddedScore(newAddedScore);
    }
  }, [additionalData.definitions, personnelData.data, filters.year]);

  const handleYearChange = (value) => {
    setFilters({ ...filters, year: value });
  };

  const totalScore = addedScore?.reduce((total, item) => total + (item.score === '-' ? 0 : item.score), 0);

  const columns = [
    {
      Header: () => <span>{t('page_content.human_resources.rating.personnel_data_tab.field_placeholder_definition')}</span>,
      accessor: 'name',
      minWidth: 300,
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>{t('page_content.human_resources.rating.personnel_data_tab.field_placeholder_updated_at')}</span>,
      accessor: 'score_updated_at',
      Cell: (row) => (row?.value ? moment(row.value)?.format(defaultDateFormat) : '-'),
      width: 150,
      sortable: false,
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>{t('page_content.human_resources.rating.personnel_data_tab.field_placeholder_value')}</span>,
      accessor: 'scoring',
      Cell: (row) => {
        return (
            <div style={{ display: 'flex', gap: 10, padding: 5 }}>
            {row?.original?.value || '-'}
            </div>
        );
      },
      minWidth: 300,
      sortable: false,
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>{t('page_content.human_resources.rating.personnel_data_tab.field_placeholder_score')}</span>,
      Footer: () => <span>{t('page_content.human_resources.rating.personnel_data_tab.field_placeholder_footer_total')}: {totalScore}</span>,
      accessor: 'score',
      width: 125,
      sortable: false,
      style: { cursor: 'default' },
    },
  ];

  return (
    <div className="ratings__screen">
      <div className="ratings__screen__filters">
        <div className="double_box">
          <span style={{ fontSize: '13px', color: '#555', fontWeight: '700' }}>{t('page_content.human_resources.rating.personnel_data_tab.filter_by_year')}:</span>
          <DatePicker
            selected={filters?.year ? moment(filters?.year, 'YYYY').toDate() : null}
            onChange={(date) => handleYearChange(date ? moment(date).format('YYYY') : null)}
            placeholderText="----"
            showYearPicker
            dateFormat="yyyy"
            isClearable
            maxDate={moment().toDate()}
          />
        </div>
      </div>
      <Table
        style={{ userSelect: 'text' }}
        columns={columns}
        data={addedScore}
        defaultPageSize={15}
        loading={personnelData.isLoading || additionalData.isLoading}
        minRows={0}
        defaultSorted={[{ id: 'order', desc: true }]}
        onSortedChange={(newSorted) => {
          setFilters({
            ...filters,
            selectedSort: newSorted[0].id,
            selectedAscDesc: newSorted[0].desc ? 'desc' : 'asc',
          });
        }}
      />
      <TableButtons
        previous={additionalData?.previous}
        next={additionalData?.next}
        fetchFunction={fetchPaginatedDataDefinitions}
        count={additionalData?.count}
      />
    </div>
  );
};

PersonnelDataRating.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  selectedWorker: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: get(state, 'currentUser', null),
    companyId: get(state, 'app.company.id', null),
  };
};

export default connect(mapStateToProps)(withTranslation()(PersonnelDataRating));
