import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Table, TableButtons, Button, ConfirmationModal } from 'shared';

import '../styles.scss';
import IndividualGoalsModal from './IndividualGoalsModal';

import { getIndividualGoalTemplates, getPaginatedIndividualGoalTemplate, deleteIndividualGoalTemplate, getAllDepartments } from '../../../actions';

const IndividualGoalsTable = ({ t, currentUser, companyId, locationId }) => {
  const [individualGoals, setIndividualGoals] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: false,
  });
  const [individualGoalsFilters, setIndividualGoalsFilters] = useState({
    selectedAscDesc: 'desc',
    selectedSort: 'name',
  });
  const [additionalData, setAdditionalData] = useState({
    departments: [],
    isLoading: true,
  });
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [modalData, setModalData] = useState({
    isOpen: false,
    selectedItem: null,
  });

  const fetchIndividualGoalTemplates = () => {
    setIndividualGoals((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const asc = individualGoalsFilters?.selectedAscDesc === 'desc' ? '-' : '';
    const apiFilters = `&limit=15&order_by=${asc}${individualGoalsFilters?.selectedSort}`;

    getIndividualGoalTemplates(companyId, apiFilters)
      .then((res) => {
        setIndividualGoals({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setIndividualGoals((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchPaginatedIndividualGoalTemplates = (url) => {
    setIndividualGoals((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getPaginatedIndividualGoalTemplate(url)
      .then((res) => {
        setIndividualGoals({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setIndividualGoals((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchDepartments = () => {
    setAdditionalData((prevState) => ({ ...prevState, isLoading: true }));
    getAllDepartments(locationId)
      .then((res) => {
        setAdditionalData((prevState) => ({ ...prevState, departments: get(res, 'data.results', []), isLoading: false }));
      })
      .catch(() => {
        setAdditionalData((prevState) => ({ ...prevState, isLoading: false }));
      });
  };

  useEffect(() => {
    if (additionalData?.departments) fetchIndividualGoalTemplates();
  }, [additionalData, individualGoalsFilters]);

  useEffect(() => {
    fetchDepartments();
  }, [currentUser, companyId, locationId]);

  const handleShowConfirmationDialog = (original) => {
    setShowConfirmationDialog(true);
    setDeleteData(original);
  };

  const handleDeleteItem = async () => {
    await deleteIndividualGoalTemplate(deleteData?.id, companyId);
    setShowConfirmationDialog(false);
    fetchIndividualGoalTemplates();
  };

  const handleSorting = (sortData) => {
    const sortKey = sortData.id;

    setIndividualGoalsFilters((prevState) => ({
      ...prevState,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
      selectedSort: sortKey,
    }));
  };

  const handleTableRowClick = (row) => {
    setModalData({
      isOpen: true,
      selectedItem: row,
    });
  };

  const handleAddNewIndividualGoalTemplate = () => {
    setModalData({
      isOpen: true,
      selectedItem: null,
    });
  };

  const handleCloseModal = () => {
    setModalData({
      isOpen: false,
      selectedItem: null,
    });
  };

  const closeModalAndRefetch = () => {
    handleCloseModal();
    fetchIndividualGoalTemplates();
  };

  return (
    <React.Fragment>
      <div className="individual_goals_table">
        <h3>{t('page_content.human_resources.control_panel.individual_goals.table_name_individual_goals')}</h3>

        <div className="table_action_buttons">
        <Button type="add" onClick={handleAddNewIndividualGoalTemplate}>
          {t('page_content.human_resources.control_panel.individual_goals.button_add_new_individual_goals')}
        </Button>
        </div>

        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('page_content.human_resources.control_panel.individual_goals.column_name_individual_goals')}</span>,
              accessor: 'name',
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => <span>{t('page_content.human_resources.control_panel.individual_goals.column_name_individual_department')}</span>,
              accessor: 'department',
              Cell: (row) => (additionalData?.departments?.find((dep) => dep.id === row?.value)?.name || '-'),
              style: {
                cursor: 'default',
              },
            },
          ]}
          data={individualGoals.data || []}
          defaultPageSize={15}
          loading={individualGoals.isLoading}
          minRows={0}
          enableEdit
          enableDelete
          onEdit={(original) => handleTableRowClick(original)}
          onDelete={(original) => handleShowConfirmationDialog(original)}
          defaultSorted={[{ id: 'name', desc: true }]}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
        />
        <div>
          <span style={{ float: 'right' }}>
            <TableButtons
              previous={individualGoals.previous}
              next={individualGoals.next}
              fetchFunction={fetchPaginatedIndividualGoalTemplates}
              count={individualGoals.count}
            />
          </span>
        </div>
      </div>

      {modalData?.isOpen &&
      <IndividualGoalsModal
        companyId={companyId}
        currentUser={currentUser}
        isOpen={modalData?.isOpen}
        handleCloseModal={handleCloseModal}
        initialValues={modalData?.selectedItem}
        departments={additionalData?.departments}
        closeModalAndRefetch={closeModalAndRefetch}
      />}

      <ConfirmationModal
        itemName={`${get(deleteData, 'name')}`}
        showModal={showConfirmationDialog}
        handleCloseModal={() => setShowConfirmationDialog(false)}
        handleConfirmModal={handleDeleteItem}
        type="warning"
      />
    </React.Fragment>
  );
};

IndividualGoalsTable.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
  currentUser: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: get(state, 'currentUser', null),
    companyId: get(state, 'app.company.id', null),
    locationId: get(state, 'app.location.id', null),
  };
};

export default connect(mapStateToProps, null)(withTranslation()(IndividualGoalsTable));
