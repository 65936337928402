import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Tooltip from 'rc-tooltip';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Timeline, { TimelineMarkers, CursorMarker } from 'react-calendar-timeline';

import { processOEE } from './helpers';
import './styles.scss';
import { defaultDateTimeFormat } from '../../../constants';

const OEEGantt = ({ t, from, to, width, yAxisWidth, oeeGanttLineHeight, oeeGanttData }) => {
  const [oeeData, setOeeData] = useState(null);

  useEffect(() => {
    if (oeeGanttData?.timeline) {
      setOeeData(processOEE(oeeGanttData?.timeline));
    }
  }, [oeeGanttData]);

  const itemRenderer = ({ item, itemContext, getItemProps }) => {
    return (
      <Tooltip
        id={(item.id).toString()}
        trigger={['hover']}
        placement="top"
        overlay={
          !itemContext.dragging ?
            <div className="reacttooltip">
                <span>{`${item?.start_time ? moment(item.start_time).format(defaultDateTimeFormat) : ''} - ${item?.end_time ? moment(item?.end_time).format(defaultDateTimeFormat) : ''}`}</span>
                <br />
                <span>{item?.order || ''}</span>
                <br />
                <span>{`${item?.product || ''} (${item?.description || ''})`}</span>
                <br />
                <span>
                    <label>{t('page_content.dashboards.oee_gantt.quantity')}: </label>
                    {item?.quantity || ''}
                </span>
                <br />
                <span>
                    <label>OEE: </label>
                    {item?.oee || ''}
                </span>
            </div> : null
        }
      >
        <div {...getItemProps({ style: { zIndex: '9999 !important' } })}>
          <div
            style={{
              height: itemContext.dimensions.height,
              overflow: 'hidden',
              paddingLeft: 3,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              position: 'relative',
              background: item?.color?.background,
              border: item?.color?.border,
              color: item?.color?.color,
            }}
          />
        </div>
      </Tooltip>
    );
  };

  if (!oeeData) {
    return null;
  }

  return (
    <div key={width} className="oee-gantt" style={{ paddingLeft: yAxisWidth - 3, marginRight: 3 }}>
        <Timeline
          timelineWidth={width - yAxisWidth}
          groups={[{ id: 1, title: 'Group1' }]}
          items={oeeData || []}
          itemRenderer={itemRenderer}
          visibleTimeStart={from}
          visibleTimeEnd={to}
          stackItems={false}
          lineHeight={oeeGanttLineHeight}
          itemHeightRatio={0.75}
          canResize={false}
          sidebarWidth={0}
        >
          <TimelineMarkers>
            <CursorMarker>
              {({ styles }) => <div style={{ ...styles, backgroundColor: 'gray' }} />}
            </CursorMarker>
          </TimelineMarkers>
        </Timeline>
    </div>
  );
};

OEEGantt.propTypes = {
  i18n: PropTypes.object,
  t: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  from: PropTypes.string,
  to: PropTypes.string,
  width: PropTypes.number,
  yAxisWidth: PropTypes.number,
  settings: PropTypes.object,
  oeeGanttLineHeight: PropTypes.number,
  type: PropTypes.string,
  oeeGanttData: PropTypes.array,
  colorSet: PropTypes.array,
};

export default withTranslation()(OEEGantt);
