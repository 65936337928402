import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { Table, TableButtons, Button, ConfirmationModal } from 'shared';
import '../../../styles.scss';

import { getEquipmentInfo, getPaginatedEquipmentInfo, deleteEquipmentInfo } from '../../../actions';

import EquipmentCatalogueModal from './EquipmentCatalogueModal';

const EquipmentCatalogueTab = ({ t, companyId, currentUser }) => {
  const [equipment, setEquipment] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: true,
  });
  const [equipmentFilters, setEquipmentFilters] = useState({
    selectedAscDesc: 'asc',
    selectedSort: 'name',
  });

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [modalData, setModalData] = useState({
    isOpen: false,
    selectedItem: null,
  });

  const fetchEquipmentInfo = () => {
    setEquipment((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const asc = equipmentFilters?.selectedAscDesc === 'desc' ? '-' : '';
    const apiFilters = `&limit=30&order_by=${asc}${equipmentFilters?.selectedSort}`;

    getEquipmentInfo(companyId, apiFilters)
      .then((res) => {
        setEquipment({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setEquipment((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchPaginatedEquipmentInfo = (url) => {
    setEquipment((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getPaginatedEquipmentInfo(url)
      .then((res) => {
        setEquipment({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setEquipment((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  useEffect(() => {
    fetchEquipmentInfo();
  }, [equipmentFilters]);

  const handleShowConfirmationDialog = (row) => {
    setShowConfirmationDialog(true);
    setDeleteData(row);
  };

  const handleDeleteItem = async () => {
    await deleteEquipmentInfo(deleteData?.id, companyId);
    setShowConfirmationDialog(false);
    fetchEquipmentInfo();
  };

  const handleSorting = (sortData) => {
    const sortKey = sortData.id;

    setEquipmentFilters((prevState) => ({
      ...prevState,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
      selectedSort: sortKey,
    }));
  };

  const handleTableRowClick = (row) => {
    setModalData({
      isOpen: true,
      selectedItem: row,
    });
  };

  const handleAddNewEquipment = () => {
    setModalData({
      isOpen: true,
      selectedItem: null,
    });
  };

  const handleCloseModal = () => {
    setModalData({
      isOpen: false,
      selectedItem: null,
    });
  };

  const closeModalAndRefetch = () => {
    handleCloseModal();
    fetchEquipmentInfo();
  };

  return (
    <>
      <div className="assigned_equipment__table">
        <div className="assigned_equipment__filters">
            <div className="assigned_equipment__filters__actions">
                <Button
                  type="add"
                  onClick={handleAddNewEquipment}
                >
                    {t('page_content.human_resources.assigned_equipment_and_info.equipment_catalogue.button_add_new_equipment')}
                </Button>
            </div>
        </div>

        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('page_content.human_resources.assigned_equipment_and_info.equipment_catalogue.column_name')}</span>,
              accessor: 'name',
            },
          ]}
          minRows={0}
          noDataText={t('page_content.human_resources.assigned_equipment_and_info.equipment_catalogue.table_no_data_found')}
          defaultPageSize={30}
          enableEdit
          enableDelete
          onEdit={(row) => handleTableRowClick(row)}
          onDelete={(row) => handleShowConfirmationDialog(row)}
          data={equipment.data || []}
          loading={equipment.isLoading}
          defaultSorted={[{ id: 'name', desc: false }]}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
        />
        <div>
          <span style={{ float: 'right' }}>
            <TableButtons
              next={equipment.next}
              count={equipment.count}
              previous={equipment.previous}
              fetchFunction={fetchPaginatedEquipmentInfo}
            />
          </span>
        </div>
      </div>

      {
        modalData?.isOpen &&
            <EquipmentCatalogueModal
              companyId={companyId}
              currentUser={currentUser}
              isOpen={modalData?.isOpen}
              handleCloseModal={handleCloseModal}
              initialValues={modalData?.selectedItem}
              closeModalAndRefetch={closeModalAndRefetch}
            />
      }

      <ConfirmationModal
        type="warning"
        showModal={showConfirmationDialog}
        handleConfirmModal={handleDeleteItem}
        itemName={`${get(deleteData, 'name')}`}
        handleCloseModal={() => setShowConfirmationDialog(false)}
      />
    </>
  );
};

EquipmentCatalogueTab.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  currentUser: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: get(state, 'currentUser', null),
    companyId: get(state, 'app.company.id', null),
  };
};

export default connect(mapStateToProps)(withTranslation()(EquipmentCatalogueTab));
